import React, { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const AboutPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
  }, []);
  return (
    <div
      style={{
        overflow: "initial",
        backgroundColor: "white",
      }}
      className=" position-relative loginContainer"
    >
      <style>
        {`
                .firstGrid {
                    display: grid;
                    gap: 10px;
                    grid-template-columns: repeat(5, 1fr);
                }
.secondGrid{
display: grid;
gap: 10px;
grid-template-columns: repeat(3,1fr);
}
                @media (max-width: 600px) {
                    .firstGrid {
                        grid-template-columns: repeat(2, 1fr);
                    }
                        .secondGrid{
                            grid-template-columns: repeat(1, 1fr);
                        }
                }
            `}
      </style>
      <div
        style={{
          display: "flex",
          padding: "0.5rem",
          position: "fixed",
          top: 0,
          backgroundColor: "black",
          zIndex: 30,
        }}
        className="sticky-header"
      >
        <div
          onClick={() => navigate(-1)}
          style={{ color: "white", cursor: "pointer" }}
        >
          <div style={{ marginLeft: "1rem" }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
              alt=""
              style={{ width: "25px", height: "25px" }}
            />
          </div>
        </div>
        <h5
          style={{
            margin: 0,
            padding: 0,
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            flex: 1,
            marginTop: "0.25rem",
            fontSize: "0.9rem",
          }}
        >
          {t("about")}
        </h5>
      </div>
      <br />
      <div>
        <div
          style={{
            padding: "1rem",
            background: "linear-gradient(to right, #1b3ea6b0, #061f71e1)",
          }}
        >
          <div
            className=" row col-12 "
            style={{
              margin: "0 auto",
              paddingTop: "1rem",
            }}
          >
            <div
              className=" col-md-8 col-12"
              style={{
                display: "flex",
                justifyContent: "center",
                flex: "1",
                marginTop: "0.5rem",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  paddingTop: "0.5rem",
                  fontSize: "1.9rem",
                  color: "rgba(255, 255, 255, 0.827)",
                  fontWeight: 650,
                }}
                className=""
              >
                <span style={{ color: "#ffa23c", fontWeight: 650 }}>
                  {t("ourMission")}
                </span>{" "}
                {t("toHelp")}
              </h3>
            </div>
            <div
              className=" col-12 col-md-4 order-md-last order-first"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flex: "1",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/aboutamz.svg`}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "1rem",
            margin: "1rem",
            backgroundColor: "rgba(241, 218, 218, 0.862)",
          }}
        >
          <h5
            style={{
              margin: "0.75rem 0",
              color: "rgb(64, 60, 60)",
              fontWeight: 480,
              fontSize: "1rem",
            }}
          >
            {t("scoutCorp")}
          </h5>

          <h5
            style={{
              margin: "0.75rem 0",
              color: "rgb(64, 60, 60)",
              fontWeight: 480,
              fontSize: "1rem",
            }}
          >
            {t("scoutUser")}
          </h5>

          <h5
            style={{
              margin: "0.75rem 0",
              color: "rgb(64, 60, 60)",
              fontWeight: 480,
              fontSize: "1rem",
            }}
          >
            {t("scoutBecame")}
          </h5>

          <h5
            style={{
              margin: "0.75rem 0",
              color: "rgb(64, 60, 60)",
              fontWeight: 480,
              fontSize: "1rem",
            }}
          >
            {t("scoutOperated")}
          </h5>
        </div>

        <h5
          style={{
            padding: "1rem",
            margin: "0.75rem",
            backgroundColor: "white",
            color: "rgb(66, 61, 61)",
            fontSize: "1rem",
          }}
        >
          {t("coreValues")}
        </h5>

        <div
          style={{
            padding: "1rem",
            margin: "1rem",
            backgroundColor: "#e6e4e4",
          }}
        >
          <h2
            style={{
              textAlign: "center",
              marginBottom: "0.5rem",
              color: "rgb(75, 70, 70)",
              fontWeight: 800,
            }}
          >
            {t("ourCustomers")}
          </h2>
          <div style={{ textAlign: "center" }}>{t("proudToSee")}</div>

          <div
            style={{
              margin: "0 2rem",
              padding: 0,
            }}
            className="firstGrid  my-3"
          >
            <div
              style={{
                padding: "0.5rem",
                // backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/hp.svg`}
                style={{ width: "45px", height: "45px" }}
                alt=""
              />
              {/* <div>HP</div> */}
            </div>
            <div
              style={{
                padding: "0.5rem",
                // backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/alibaba.svg`}
                style={{ marginTop: "0.5rem" }}
                alt=""
              />
              {/* <div>Alibaba</div> */}
            </div>
            <div
              style={{
                padding: "0.5rem",
                // backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/redbull.svg`}
                // style={{ width: "45px", height: "45px" }}
                alt=""
              />
              {/* <div>RedBull</div> */}
            </div>
            <div
              style={{
                padding: "0.5rem",

                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/disney.svg`}
                alt=""
              />
            </div>
            <div
              style={{
                padding: "0.5rem",

                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/dell.svg`}
                alt=""
              />
            </div>
            <div
              style={{
                padding: "0.5rem",

                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/casip.svg`}
                alt=""
              />
            </div>
          </div>
        </div>

        <div style={{ padding: "1rem", margin: "1rem" }}>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "0.5rem",
              color: "rgb(75, 70, 70)",
              fontWeight: 800,
            }}
          >
            {t("weAreHiring")}
          </h2>
          <div style={{ textAlign: "center" }}>{t("builtProducts")}</div>

          <div
            style={{
              margin: "0 2rem",
              padding: 0,
            }}
            className=" secondGrid"
          >
            <div
              style={{
                padding: "0.5rem",
                backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/remote.svg`}
                alt=""
              />
              <div>{t("flexibleWorkHours")}</div>
            </div>

            <div
              style={{
                padding: "0.5rem",
                backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/flexible.svg`}
                alt=""
              />
              <div>{t("educationBenefits")}</div>
            </div>

            <div
              style={{
                padding: "0.5rem",
                backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/education.svg`}
                alt=""
              />
              <div>{t("openVacationPolicy")}</div>
            </div>

            <div
              style={{
                padding: "0.5rem",
                backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/vacation.svg`}
                alt=""
              />
              <div>{t("100%remote")}</div>
            </div>

            <div
              style={{
                padding: "0.5rem",
                backgroundColor: "#ecf0f0",
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/retreats.svg`}
                alt=""
              />
              <div>{t("companyRetreats")}</div>
            </div>
          </div>
        </div>

        <div style={{ padding: "1rem", margin: "1rem" }}>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "0.5rem",
              color: "rgb(75, 70, 70)",
              fontWeight: 800,
            }}
          >
            {t("getInTouch")}
          </h2>

          <div className=" mapContainer">
            <div className="bg-white row my-4 p-0">
              <div className="col-md-9 mt-3 col-12">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/map1.png`}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="col-md-3 mt-3 d-flex flex-column justify-content-center col-12">
                <div>
                  <h4 style={{ color: "#ffa23c", fontWeight: 700 }}>
                    AMZScout HQ
                  </h4>
                  <div style={{ fontWeight: 600, color: "rgb(75, 72, 72)" }}>
                    1735 Market Street, Suite 3750, Philadelphia, PA 19103
                  </div>
                  <div>
                    <span style={{ fontWeight: 600 }}>Email</span>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      : support@amzscout.net
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white row my-4 p-0">
              <div className="col-md-9 mt-3 col-12">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/map2.png`}
                  alt=""
                  className="w-100"
                />
              </div>
              <div className="col-md-3 mt-3 d-flex flex-column justify-content-center col-12">
                <div>
                  <h4 style={{ color: "#ffa23c", fontWeight: 700 }}>
                    Growthco DMCC
                  </h4>
                  <div style={{ fontWeight: 600, color: "rgb(75, 72, 72)" }}>
                    <div>Unit No: BA402</div>
                    <div>DMCC Business Centre</div>
                    <div>Level No 1</div>
                    <div>Jewellery & Gemplex 3</div>
                    <div>Dubai</div>
                    <div>United Arab Emirates</div>
                  </div>
                  <div>
                    <span style={{ fontWeight: 600 }}>Email</span>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      : support@amzscout.net
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
