import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };

const UsdtPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [address, setAddress] = useState(
    localStorage.getItem("woopraLocation")
  );

  const [error, setError] = useState("");

  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const handleConfirm = () => {
    if (!address) {
      setError(t("enterTheAddress"));
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    } else {
      setError("");
      localStorage.setItem("woopraLocation", address);
      navigate(-1);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
  });
  return (
    <div
      className="loginBody   position-relative   "
      style={{
        overflow: "initial !important",
        backgroundColor: "#f9f9f9",
        height: "100vh",
      }}
    >
      <div
        className="loginContainer   position-relative top-0"
        style={{ backgroundColor: "#f9f9f9", height: "100vh" }}
      >
        <div
          style={{}}
          className="d-flex py-2 sticky-header position-fixed top-0"
        >
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            {t("tradingAccount")}
          </h5>
        </div>
        <br />

        <div
          style={{ marginTop: "1rem", padding: "1rem", paddingBottom: "3rem" }}
        >
          <h5 style={{ marginLeft: "1rem", color: "black", fontWeight: 600 }}>
            {t("receivingAddress")}
          </h5>
          <div style={{ padding: "1rem", backgroundColor: "white" }}>
            <input
              type="text"
              className="pl-2 w-100 border-0 outline-none"
              id="address"
              placeholder={t("digitalCurrencyWalletAddress")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
            />
          </div>
          {error && (
            <small
              id="addressError"
              style={{ color: "red", marginLeft: "1rem" }}
            >
              {error}
            </small>
          )}
        </div>
        <button
          onClick={handleConfirm}
          className="sticky-footer m-0 "
          style={{
            position: "fixed",
            bottom: "0",
            marginTop: "3rem",
            padding: "1rem",
            backgroundColor: "black",
            border: "none",
            color: "white",

            fontWeight: "bold",
          }}
        >
          <h4 id="usdtConfirm" style={{ fontWeight: "normal" }}>
            {t("confirm")}
          </h4>
        </button>
      </div>
    </div>
  );
};
export default UsdtPage;
