import logo from "./logo.svg";
import { useEffect, useState } from "react";
import "./index.css";

/*import {useArray} from './reducer';*/

//import { BrowserRouter, Route, Routes } from "react-router";
// import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { NavBar } from "./navBar";
import { Home } from "./pages/home";
import RegisterPage from "./pages/register";
import LoginPage from "./pages/login";
import MessagesPage from "./pages/messages";
import StartingPage from "./pages/starting";
import RecordsPage from "./pages/records";
import SettingsPage from "./pages/setting";
import WithdrawPage from "./pages/withdraw";
import OrderPage from "./pages/order";
import DepositPage from "./pages/deposit";
import InformationPage from "./pages/information";
import UpdatePasswordPage from "./pages/updatePassword";
import updateTransactionPassword from "./pages/updateTransactionPassword";
import MyTeamPage from "./pages/myTeam";
import TeamRevenuePage from "./pages/teamRevenue";
import VipLevelPage from "./pages/vipLevels";
import WaysPage from "./pages/ways";
import PlayPage from "./pages/play";
import MoreServicesPage from "./pages/moreServices";
import MyNoticePage from "./pages/myNotice";
import NoticePage from "./pages/notice";
import AboutPage from "./pages/about";
import ClausePage from "./pages/clause";
import CollectionAreaPage from "./pages/collectionArea";
import CommisionStatementPage from "./pages/commisionStatement";
import DhistoryPage from "./pages/dhistory";
import EventPage from "./pages/event";
import FaqsPage from "./pages/faqs";
import TacPage from "./pages/tas";
import UsdtPage from "./pages/usdt";
import WhistoryPage from "./pages/whistory";
import UpdateTransactionPasswordPage from "./pages/updateTransactionPassword";
import "./index.css";
import CustomerServicePage from "./pages/customerService";

// import { useTranslation } from "react-i18next";
function App() {
  return (
    <div className=" overflow-hidden" style={{ backgroundColor: "#f9f9f9" }}>
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <BrowserRouter>
          <Routes>
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />

            {/* finished Start */}
            <Route path="about" element={<AboutPage />} />
            <Route
              path="commisionStatement"
              element={<CommisionStatementPage />}
            />
            <Route path="customerService" element={<CustomerServicePage />} />
            <Route path="notice" element={<NoticePage />} />
            <Route path="faqs" element={<FaqsPage />} />
            <Route path="tac" element={<TacPage />} />
            <Route path="myNotice" element={<MyNoticePage />} />
            <Route path="clause" element={<ClausePage />} />
            <Route path="vipLevel" element={<VipLevelPage />} />
            <Route path="collectionArea" element={<CollectionAreaPage />} />
            <Route path="usdt" element={<UsdtPage />} />
            <Route path="order" element={<OrderPage />} />
            <Route path="event" element={<EventPage />} />
            <Route path="play" element={<PlayPage />} />
            <Route path="/" element={<NavBar />}>
              <Route index element={<Home />} />
              <Route path="messages" element={<MessagesPage />} />
              <Route path="starting" element={<StartingPage />} />
              <Route path="records" element={<RecordsPage />} />
              <Route path="settings" element={<SettingsPage />} />
            </Route>
            <Route path="ways" element={<WaysPage />} />
            <Route path="deposit" element={<DepositPage />} />
            <Route path="dhistory" element={<DhistoryPage />} />
            <Route path="whistory" element={<WhistoryPage />} />
            <Route path="withdraw" element={<WithdrawPage />} />
            <Route path="moreServices" element={<MoreServicesPage />} />
            <Route path="information" element={<InformationPage />} />
            <Route path="updatePassword" element={<UpdatePasswordPage />} />
            <Route
              path="updateTransactionPassword"
              element={<UpdateTransactionPasswordPage />}
            />
            <Route path="myTeam" element={<MyTeamPage />} />
            <Route path="teamRevenue" element={<TeamRevenuePage />} />
            {/* finished end */}
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}
/*  <img
src="https://themeskingdom.com/blog/wp-content/uploads/2019/12/12instagram.png"
className="logo"
/>*/
export default App;
