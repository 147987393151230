import React, { useEffect, useState } from "react";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import id from "../translations/id.json";
import en from "../translations/en.json";
import { useNavigate } from "react-router";
import axios from "axios";
const translations = { en, id };
const MessagesPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const [notifications, setNotifications] = useState([]);
  const [showRead, setShowRead] = useState(false);

  const navigate = useNavigate();
  const userData = JSON.parse(getUser());
  const token = getToken();
  const adminDomain = "https://admin.amzscoutmembers.com/";
  const domain = "https://amzscoutmembers.com/";
  const [errorTransactionPassword, setErrorTransactionPassword] =
    useState(false);

  const toggleShowRead = () => {
    setShowRead(!showRead);
  };

  // timestamp
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/messages",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setNotifications(response.data.data);
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div className="messagesContainer">
      <div className="mt-4 ms-3">
        <h6
          style={{ color: "black", fontWeight: 650 }}
          className="ms-md-0 ms-2"
        >
          {t("customerServiceCenter")}
        </h6>
      </div>

      <div className="mt-4 bg-white">
        <div className="float-start">
          <img
            className="ms-md-0 ms-4"
            src={`${process.env.PUBLIC_URL}/images/No need to edit/js.png`}
            style={{ width: "70px", height: "70px", borderRadius: "6px" }}
            alt=""
          />
          <div className="d-inline ms-3" style={{ fontWeight: 650 }}>
            {t("customerService")}
          </div>
        </div>
        <a
          href="https://chat.ichatlink.net/widget/standalone.html?eid=1bd6c86911da3341f0c6425db354a301&language=en&metadata={%22name%22:%222224-UserName%22}"
          className="text-decoration-none float-end"
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
            style={{ width: "20px", height: "20px" }}
            className="me-2 mt-3"
            alt=""
          />
        </a>
      </div>

      <br />
      <br />
      <br />

      <div className="mt-5">
        <div className="float-start">
          <div className="d-inline ms-3" style={{ fontWeight: 650 }}>
            {t("systemNotification")}
          </div>
        </div>
        <div className="float-end">
          <div className="d-inline ms-3" style={{ fontWeight: 650 }}>
            {t("hide")} {t("read")}
          </div>
          <div
            className="me-3 ms-3 px-2 text-white"
            id="myoff"
            style={{
              display: showRead ? "none" : "inline",
              cursor: "pointer",
              backgroundColor: "rgb(200, 188, 188)",
              borderRadius: "10px",
              fontSize: ".9rem",
            }}
            onClick={toggleShowRead}
          >
            {t("off")}
          </div>
          <div
            className="me-3 ms-3 px-2 text-white"
            id="on"
            style={{
              display: showRead ? "inline" : "none",
              cursor: "pointer",
              backgroundColor: "rgb(200, 188, 188)",
              borderRadius: "10px",
              fontSize: ".9rem",
            }}
            onClick={toggleShowRead}
          >
            {t("on")}
          </div>
        </div>
      </div>

      <br />

      <div
        id="all"
        style={{ display: !showRead ? "inline" : "none" }}
        className="mx-2 overflow-hidden notiContainer mt-3"
      >
        {notifications.map((notification) =>
          notification.amount ? (
            <a
              href="dhistory"
              className="text-decoration-none col-12 row mt-3 eachNoti bg-white"
              key={notification.id}
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the entire notification
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <div className="col-1">
                <div className="d-flex justify-content-start">
                  <div>
                    {!notification.is_seen && (
                      <div className="d-flex pt-3 justify-content-end">
                        <div
                          className="duplicate p-1 rounded-circle"
                          style={{
                            backgroundColor: "red",
                            width: "8px", // Slightly larger for better visibility
                            height: "8px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Shadow for the dot
                          }}
                        >
                          .
                        </div>
                      </div>
                    )}
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "8px", // Rounded corners for the image
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the image
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-7 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                <div>
                  <div
                    className="eachMessageText text-decoration-none ps-2"
                    style={{
                      fontWeight: 660,
                      color: "black",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    {t("currentAcountAccumulates")} {notification.amount}
                  </div>
                  <small
                    className="ps-2 text-decoration-none timestamp"
                    data-timestamp={notification.created_at}
                    style={{
                      color: "black",
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    {formatTimestamp(notification.created_at)}
                  </small>
                </div>
              </div>

              <div className="col-md-4 col-1 d-flex flex-column justify-content-center align-items-end">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                  style={{
                    width: "20px",
                    height: "20px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the icon
                  }}
                  className="me-2 mt-3"
                  alt=""
                />
              </div>
            </a>
          ) : (
            <div className="my-2">
              <div
                className="text-decoration-none col-12 row mt-2 eachNoti bg-white"
                style={{
                  borderRadius: "4px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow here
                }}
              >
                <div className="col-12">
                  <div
                    className="d-flex  justify-content-start"
                    style={{
                      borderBottom: "solid 0.5px rgb(162, 159, 159)",
                    }}
                  >
                    <div
                      className="py-2 text-black-50 timestamp"
                      data-timestamp={notification.created_at}
                    >
                      {formatTimestamp(notification.created_at)}
                      {/* <i className="pt-3 fas fa-mail-bulk" style={{ fontSize: '1.9rem' }}></i> */}
                    </div>
                    <hr />
                  </div>
                </div>

                <div className="col-12  d-flex pl-md-2 py-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/message.jpg`}
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "8px", // Rounded corners for the image
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the image
                    }}
                    alt=""
                  />
                  &nbsp; &nbsp;
                  <div
                    className=" mt-2"
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                  />
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div
        id="noRead"
        className=" notiContainer mt-3"
        style={{ display: showRead ? "inline" : "none" }}
      >
        {notifications
          // .filter((notification) => notification.amount)
          .map((notification) =>
            notification.amount ? (
              <a
                href="dhistory"
                className="text-decoration-none col-12 row mt-3 eachNoti bg-white"
                key={notification.id}
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for the entire notification
                  borderRadius: "8px",
                  padding: "10px",
                  backgroundColor: "#fff",
                  display: !notification.is_seen ? "inline" : "none",
                }}
              >
                <div className="col-1">
                  <div className="d-flex justify-content-start">
                    <div>
                      {!notification.is_seen && (
                        <div className="d-flex pt-3 justify-content-end">
                          <div
                            className="duplicate p-1 rounded-circle"
                            style={{
                              backgroundColor: "red",
                              width: "8px", // Slightly larger for better visibility
                              height: "8px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Shadow for the dot
                            }}
                          >
                            .
                          </div>
                        </div>
                      )}
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "8px", // Rounded corners for the image
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the image
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-7 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                  <div>
                    <div
                      className="eachMessageText text-decoration-none ps-2"
                      style={{
                        fontWeight: 660,
                        color: "black",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                      }}
                    >
                      {t("currentAcountAccumulates")} {notification.amount}
                    </div>
                    <small
                      className="ps-2 text-decoration-none timestamp"
                      data-timestamp={notification.created_at}
                      style={{
                        color: "black",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                      }}
                    >
                      {formatTimestamp(notification.created_at)}
                    </small>
                  </div>
                </div>

                <div className="col-md-4 col-1 d-flex flex-column justify-content-center align-items-end">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                    style={{
                      width: "20px",
                      height: "20px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the icon
                    }}
                    className="me-2 mt-3"
                    alt=""
                  />
                </div>
              </a>
            ) : (
              <div
                className="my-2"
                style={{
                  display: !notification.is_seen ? "inline" : "none",
                }}
              >
                <div
                  className="text-decoration-none col-12 row mt-2 eachNoti bg-white"
                  style={{
                    borderRadius: "4px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Add box shadow here
                  }}
                >
                  <div className="col-12">
                    <div
                      className="d-flex justify-content-start"
                      style={{
                        borderBottom: "solid 0.5px rgb(162, 159, 159)",
                      }}
                    >
                      <div
                        className="py-2 text-black-50 timestamp"
                        data-timestamp={notification.created_at}
                      >
                        {formatTimestamp(notification.created_at)}
                        {/* <i className="pt-3 fas fa-mail-bulk" style={{ fontSize: '1.9rem' }}></i> */}
                      </div>
                      <hr />
                    </div>
                  </div>

                  <div className="col-12  d-flex pl-md-2 py-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/message.jpg`}
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "8px", // Rounded corners for the image
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for the image
                      }}
                      alt=""
                    />
                    &nbsp; &nbsp;
                    <div
                      className=" mt-2"
                      dangerouslySetInnerHTML={{ __html: notification.message }}
                    />
                  </div>
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default MessagesPage;
