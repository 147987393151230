import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const PlayPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [context, setContext] = useState("");
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/tac"
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setContext(response.data.context);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);
  return (
    <div
      className="loginBody   position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <div
        className="loginContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div
          style={{}}
          className="d-flex py-2 sticky-header position-fixed top-0"
        >
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            {t("orderDescription")}
          </h5>
        </div>
        <br />
        <br />
        <br />
        <div className="content">
          <h1 style={{ textAlign: "center" }}>
            <span style={{ color: "rgb(0, 58, 140)" }}>
              {t("taskTypeDescription")}
            </span>
          </h1>
          <p>
            <br />
          </p>
          <hr />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <h2>
            <span style={{ color: "rgb(9, 109, 217)" }}>
              {t("taskTypeDescription")}
            </span>
          </h2>
          <p>
            Users will receive a 0.5% reward for each application they optimize.
          </p>
          <p>
            1. After the application optimization is completed, funds and
            rewards will be returned to the user account.
          </p>
          <p>
            2. The system will randomly allocate applications based on the total
            amount of the user's account.
          </p>
          <p>
            3. When the accumulated remittance amount in the user's account
            reaches the VIP upgrade threshold, the system will automatically
            upgrade the user's VIP level. The number of user tasks will also
            increase accordingly, up to VIP4. After upgrading your VIP level,
            you have a certain chance of encountering advanced tasks! The number
            of advanced tasks is rare and randomly assigned by the system!
          </p>
          <p>
            4. Common tasks are randomly assigned to user accounts and cannot be
            canceled, aborted, or skipped.
          </p>
          <p>
            5. In order to protect the rights and interests of users, please
            plan your funds reasonably and increase your income.
          </p>
          <hr />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <h2>
            <span style={{ color: "rgb(9, 109, 217)" }}>
              <strong>{t("advancedTasks")}</strong>
            </span>
          </h2>
          <ul>
            <li>
              High-level tasks consist of one to three products. The user will
              not necessarily get 3 products, the system will randomly assign
              high-level tasks! Therefore, users are more likely to get 1 to 4
              high-level tasks.
            </li>
          </ul>
          <p>Users can receive 5% rewards for completing advanced tasks.</p>
          <ul>
            <li>
              After the advanced tasks are completed, all funds and task
              commissions will be returned to the user account.
            </li>
            <li>
              The system randomly assigns high-level tasks to user accounts
              based on the total balance of the user account.
            </li>
            <li>
              When the accumulated remittance amount in the user's account
              reaches the VIP upgrade threshold, the system will automatically
              upgrade the user's VIP level. The number of user tasks will also
              increase accordingly, up to VIP4. After upgrading your VIP level,
              you have a certain chance of encountering advanced tasks! The
              number of advanced tasks is rare and randomly assigned by the
              system!
            </li>
            <li>
              Once an advanced task is started, it cannot be canceled, stopped,
              or skipped.
            </li>
          </ul>
          <hr />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <h2>
            <span style={{ color: "rgb(9, 109, 217)" }}>
              {t("propertiesAndCharacteristics")}
            </span>
          </h2>
          <p>
            1. All tasks are randomly assigned by the system to ensure fairness,
            justice, and the randomness of big data.
          </p>
          <p>
            2. Since the platform has a cooperation agreement with the merchant,
            its interests are protected! All order tasks have a time limit of 8
            hours. Users must complete data optimization within 8 hours;
            otherwise, the credit score of the user account will be affected.
          </p>
          <p>
            3. Once all tasks are started, they cannot be undone or interrupted
            midway.
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <hr />
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};
export default PlayPage;
