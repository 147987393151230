import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getToken, getUser, setToken, setUser } from "../utils/localStorage";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const Login = () => {
  const [language, setLanguage] = useState("en"); // Default language is English

  const t = (key) => translations[language][key] || key;
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorName, setErrorName] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [showUniqueModal, setShowUniqueModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validatoin, setValidation] = useState(false);
  const [falseData, setFalseData] = useState(false);
  const [roundPassword, setRoundPassword] = useState(false);

  const [chosenLanguage, setChosenLanguage] = useState("ENGLISH");
  const navigate = useNavigate();
  const nameRef = useRef();
  const passwordRef = useRef();
  const changeLanguage = (lang) => {
    localStorage.setItem("language", lang);
    setChosenLanguage(lang);
    if (lang == "ENGLISH") {
      localStorage.setItem("languageCode", "en");
      setLanguage("en");
    } else if (lang == "INDONESIA") {
      localStorage.setItem("languageCode", "id");
      setLanguage("id");
    } else if (lang == "CHINESE") {
      localStorage.setItem("languageCode", "zh");
      setLanguage("zh");
    } else {
      localStorage.setItem("languageCode", "jp");
      setLanguage("jp");
    }
    setShowModal(false);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setValidation(false);
    setFalseData(false);
    setRoundPassword(false);

    if (nameRef.current.value === "" || passwordRef.current.value === "") {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 2000);
      return;
    }
    let data = {
      name: nameRef.current.value,
      password: passwordRef.current.value,
    };
    setIsLoading(true);
    const response = await axios.post(
      "https://admin.amzscoutmembers.com/api/userLogin",
      data
    );

    if (response.data.status === "true") {
      setIsLoading(false);
      setToken(response.data.api_key);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.api_key}`;
      const userData = JSON.stringify(response.data.user);
      setUser(userData);
      navigate("/");
    }

    if (
      response.data.status === "error" &&
      response.data.data == "Incorrect User Name"
    ) {
      setIsLoading(false);
      setFalseData(true);
      setTimeout(() => {
        setFalseData(false);
      }, 2000);
      return;
    }

    if (
      response.data.status === "error" &&
      response.data.data == "Your password is incorrect"
    ) {
      setIsLoading(false);
      setRoundPassword(true);
      setTimeout(() => {
        setRoundPassword(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("language")) {
      setChosenLanguage(localStorage.getItem("language"));
    }
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    const token = getToken();
    const user = getUser();
    if (token && user) {
      navigate("/");
    }
  }, []);

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById("password");
    const togglePasswordBtn = document.getElementById("togglePasswordBtn");
    if (passwordInput.type === "password") {
      passwordInput.type = "text";

      togglePasswordBtn.src = `${process.env.PUBLIC_URL}/images/No need to edit/openEye.png`; // Base64 image for visible eye
    } else {
      passwordInput.type = "password";
      togglePasswordBtn.src = `${process.env.PUBLIC_URL}/images/Change them into Red/eye.png`; // Base64 image for hidden eye
    }
  };

  return (
    <div className="loginBody" style={{ position: "relative" }}>
      <style>
        {`
        .notification{ position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;}`}
      </style>

      <div className="loginContainer">
        <div className="d-flex loginCard py-3 px-4 border-none">
          <span>
            <a
              href="https://direct.lc.chat/18247440/"
              className="firstIcon d-flex justify-content-center align-items-center rounded-circle"
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/firstIcon.png`}
                className="w-75 h-75"
                alt=""
              />
            </a>
          </span>
          <span>
            <div
              id="openModalBtn"
              className="d-flex ms-2 text-white englishContainer p-1"
              onClick={() => setShowModal(true)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/earth.png`}
                className="ms-2 earth"
                alt=""
              />
              <h6 className="font-weight-bold pt-1 ms-2 me-1">
                {chosenLanguage}
              </h6>
            </div>
          </span>
        </div>

        <div className="logoContainer">
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/js.png`}
            style={{ width: "140px", height: "96px" }}
            alt=""
          />
        </div>

        <div
          // action="/login"
          // method="POST"
          className="loginForm"
          style={{ backgroundColor: "white" }}
        >
          <div className="p-4 loginInput border-bottom border-1 border-warning">
            <input
              name="name"
              id="name"
              type="text"
              ref={nameRef}
              className="ps-2 w-100 inputLogin border-0"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Username/Phone"
            />
            {errorName && (
              <small className="text-danger">
                {t("requireUserNameOrPhone")}
              </small>
            )}
          </div>
          <div className="mb-4 p-4 loginInput">
            <input
              name="password"
              id="password"
              type="password"
              ref={passwordRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="ps-2 w-75 inputLogin border-0 password-input"
              placeholder="Password"
            />
            {errorPassword && (
              <small className="text-danger">{t("requirePassword")}</small>
            )}
            <img
              id="togglePasswordBtn"
              src={`${process.env.PUBLIC_URL}/images/Change them into Red/eye.png`}
              className="eye float-end"
              onClick={togglePasswordVisibility}
              alt="Toggle Password"
            />
          </div>
          {isLoading ? (
            <button
              type="button"
              className="mt-5 py-3 border-0 loginBtn font-weight-bold w-100"
              onClick={handleLogin}
            >
              <div className="spinner"></div>
            </button>
          ) : (
            <button
              type="button"
              className="mt-5 py-3 border-0 loginBtn font-weight-bold w-100"
              onClick={handleLogin}
            >
              <h4 className="font-weight-normal">Login</h4>
            </button>
          )}
          <button id="realLogin" type="submit" hidden>
            Login
          </button>
          <button
            type="button"
            className="mt-3 py-3 border-0 registerBtn font-weight-bold w-100"
            onClick={() => {
              setShowUniqueModal(true);
            }}
          >
            <h4 className="font-weight-normal">Register</h4>
          </button>
          <div className="d-flex justify-content-center">
            <a
              href="tac"
              className="fp-link text-decoration-none"
              style={{ fontWeight: "400" }}
            >
              {t("tac")}
            </a>
          </div>
          <div
            className="fp-link1"
            style={{ fontWeight: "450" }}
            onClick={() => setShowCustomModal(true)}
          >
            {t("tou")}
          </div>
          <div className="w-100 mt-4 d-flex justify-content-center">
            <small className="copyright">{t("allRightsReserved")}</small>
          </div>
        </div>
      </div>

      {showModal && (
        <div
          id="modal"
          className="modal"
          style={{ display: "flex", animation: "fadeIn 1.2s" }}
        >
          <div className="modal-content">
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <button
                  onClick={() => {
                    changeLanguage("ENGLISH");
                  }}
                  id="languageModalcloseBtn"
                  className=" my-2 btn px-4 "
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    color: "black",
                  }}
                >
                  ENGLISH
                </button>
                {/* <button
                  onClick={() => {
                    changeLanguage("INDONESIA");
                  }}
                  id="languageModalcloseBtn"
                  className=" my-2 btn px-4 w-75"
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    color: "black",
                  }}
                >
                  INDONESIA
                </button> */}
                {/* <button
                  onClick={() => {
                    changeLanguage("CHINESE");
                  }}
                  id="languageModalcloseBtn"
                  className=" my-2 btn px-4 w-75"
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    color: "black",
                  }}
                >
                  CHINESE
                </button>
                <button
                  onClick={() => {
                    changeLanguage("JAPANESE");
                  }}
                  id="languageModalcloseBtn"
                  className=" my-2 btn px-4 w-75"
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    color: "black",
                  }}
                >
                  JAPAN
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {showCustomModal && (
        <div
          id="customModal"
          className="custom-modal"
          style={{ display: "flex" }}
        >
          <div className="custom-modal-content">
            <div className="mt-4">
              <h5 className="font-weight-bold text-center">{t("api")}</h5>
              <div className="myOverflow" style={{ height: "300px" }}>
                {t("personalInfoDesc")}
              </div>
              <button
                id="closeCustomModal"
                className="btn w-100 text-white p-3 mt-3"
                style={{ backgroundColor: "black" }}
                onClick={() => setShowCustomModal(false)}
              >
                {t("yes")}
              </button>
            </div>
          </div>
        </div>
      )}

      {showUniqueModal && (
        <div id="uniqueModal" className="unique-modal d-flex">
          <div className="unique-modal-content px-3">
            <div className="w-100 d-flex justify-content-end">
              <h2
                id="uniqueCloseBtn"
                className="p-0 me-2 font-weight-normal"
                onClick={() => setShowUniqueModal(false)}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/OIP.jpg`}
                  style={{ width: "30px", height: "30px" }}
                  alt="Close"
                />
              </h2>
            </div>
            <h5
              style={{ color: "black" }}
              className="text-center font-weight-bolder"
            >
              {t("prohibitedRegistration")}
            </h5>
            <h5
              style={{ color: "black" }}
              className="text-center font-weight-bolder"
            >
              {t("minu18")}
            </h5>
            <div className="d-flex justify-content-center">
              <button
                id="uniqueAcceptBtn"
                className="my-3 py-2 border-0"
                style={{ backgroundColor: "black", borderRadius: "3px" }}
                onClick={() => {
                  setShowUniqueModal(false);
                  window.location.href = "/register";
                }}
              >
                <h6 className="text-white">{t("plus18")}</h6>
              </button>
            </div>
          </div>
        </div>
      )}

      {falseData && (
        <div id="noUser" className="notification" style={{ display: "flex" }}>
          {t("userNoExist")}
        </div>
      )}

      {roundPassword && (
        <div
          id="incorrectPassword"
          className="notification"
          style={{ display: "flex" }}
        >
          {t("incorrectPassword")}
        </div>
      )}

      {validatoin && (
        <div
          id="incorrectPassword"
          className="notification"
          style={{ display: "flex" }}
        >
          {t("fillAllForms")}
        </div>
      )}
    </div>
  );
};

export default Login;
