import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const VipLevelPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const myUserData = JSON.parse(getUser());
  const token = getToken();
  const adminDomain = "https://admin.amzscoutmembers.com/";
  // Mock data
  const [userData, setUserData] = useState({
    name: "",
    levelImg: "",
    reputation_score: 100,
    memberLevelName: "",
    vip_level: "",
  });

  const [vipData, setVipData] = useState([
    {
      id: 1,
      name: "Gold VIP",
      minimum_amount: 100,
      maximum_amount: 500,
      description:
        "Gold VIP level provides exclusive access to premium features.",
      levelImg: "gold-vip.png",
    },
    {
      id: 2,
      name: "Platinum VIP",
      minimum_amount: 500,
      maximum_amount: 1000,
      description: "Platinum VIP level offers additional benefits and rewards.",
      levelImg: "platinum-vip.png",
    },
    {
      id: 3,
      name: "Diamond VIP",
      minimum_amount: 1000,
      maximum_amount: 5000,
      description:
        "Diamond VIP level grants you the highest level of privileges.",
      levelImg: "diamond-vip.png",
    },
  ]);

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/vipLevel",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setUserData(response.data.userData);
        setVipData(response.data.vipData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div
      className="loginBody    position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <style>
        {`
        .vip-header {
  top: 0;
  width: 70vw;

  z-index: 30;
  background-color: black; /* Moved from inline style */
}
.vipContainer {
 
  width: 70vw;

  margin-left: 15vw;
  margin-right: 15vw;
  background-color: white; /* Moved from inline style */
}
@media(max-width: 1300px){
    .vip-header {
  top: 0;
  width: 98vw;
    margin-left: 3px;
    margin-right: 3px;
  z-index: 30;
  background-color: black; /* Moved from inline style */
}
.vipContainer {
  overflow: initial !important;
 

   width: 98vw;
    margin-left: 3px;
    margin-right: 3px;
  background-color: white; /* Moved from inline style */
}
} 

`}
      </style>
      <div
        className="vipContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div style={{}} className="d-flex py-2 vip-header position-fixed top-0">
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            {t("vipLevels")}
          </h5>
        </div>
        <br />
        {/* <br /> */}

        <div
          style={{
            overflow: "initial",
            height: "1000px",
            backgroundColor: "black",
          }}
        >
          <div className="profilesHeader  pt-4">
            <div className="ms-2 d-flex">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                style={{ width: "65px", height: "65px" }}
                alt="Profile Logo"
              />
              <div>
                <div className="d-block ms-2 profileTextContainer">
                  <div className="m-0 p-0 d-flex">
                    <h5
                      className="m-0 p-0"
                      style={{ color: "white", fontWeight: 650 }}
                    >
                      {userData.name}
                    </h5>
                    <img
                      // src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
                      src={`${adminDomain}storage/app/public/${encodeURI(
                        userData.levelImg
                      )}`}
                      style={{ width: "24px", height: "24px" }}
                      className="ms-2"
                      alt="Level"
                    />
                  </div>
                </div>
                <div className="w-100 mt-2 ms-2">
                  <h6 className="w-100 d-flex text-white">
                    {t("creditScore")}:
                    <div
                      className="mt-2 ms-2"
                      style={{
                        backgroundColor: "rgb(241, 187, 14)",
                        height: "1px",
                        width: `${userData.reputation_score}px`,
                        borderRadius: "5px",
                        padding: "2px",
                      }}
                    ></div>
                    <div style={{ color: "white", fontWeight: 700 }}>
                      {userData.reputation_score}%
                    </div>
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className=" mx-4">
            <button
              id="vipLevelBtn"
              className="py-2 px-3 my-md-3 my-1 w-100"
              style={{
                outline: "none",
                backgroundColor: "rgb(241, 187, 14)",
                border: "none",
                borderRadius: "6px",
              }}
            >
              <div className="float-start">
                <img
                  src={`${adminDomain}storage/app/public/${encodeURI(
                    userData.levelImg
                  )}`}
                  style={{ width: "40px", height: "40px" }}
                  alt="User Level"
                />
                &nbsp;
                <h6
                  className="d-inline mt-3 ps-3"
                  style={{ color: "black", fontWeight: 700 }}
                >
                  {userData.memberLevelName}
                </h6>
              </div>
              <div className="float-end d-flex align-items-center justify-content-center">
                <div className="mt-1 " style={{ fontWeight: "bold" }}>
                  {t("currentVipLevel")}
                </div>
              </div>
            </button>
          </div>

          <div
            className="mt-md-3 mt-1 bg-black position-relative  mb-4 "
            style={{ height: "100vh" }}
          >
            <div className="mt-5">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/bigVipLogo.png`}
                style={{ width: "100%" }}
                alt="Big VIP Logo"
              />
            </div>

            <div
              style={{ overflowX: "scroll" }}
              className="position-absolute col-12 d-flex vipInfoContainer"
            >
              {vipData.map((each) => (
                <div className="col-md-5 col-12" key={each.id}>
                  <div
                    className="pt-3 position-relative px-1 w-100 vpInfo"
                    style={{ borderRadius: "15px" }}
                  >
                    <div className="w-100">
                      <div className="mb-md-4 mb-1 d-flex justify-content-between mx-md-4 mx-2">
                        <div className="ms-3">
                          <img
                            src={`${adminDomain}storage/app/public/${encodeURI(
                              userData.levelImg
                            )}`}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                        <div>
                          <h5
                            className="pt-2"
                            style={{
                              textAlign: "center",
                              color: "white",
                              fontWeight: 650,
                            }}
                          >
                            {each.name}
                          </h5>
                        </div>
                        {each.id == userData.vip_level ? (
                          <button
                            className="py-1 alreadyActivated px-2"
                            style={{
                              backgroundColor: "rgb(90, 91, 92)",
                              color: "white",
                              borderRadius: "12px",
                              border: "none",
                            }}
                          >
                            {t("alreadyActivated")}
                          </button>
                        ) : (
                          <button
                            className="py-1 alreadyActivated px-2"
                            style={{
                              backgroundColor: "rgb(90, 91, 92)",
                              color: "white",
                              borderRadius: "12px",
                              border: "none",
                            }}
                          >
                            {t("activateNow")}
                          </button>
                        )}
                      </div>

                      <br />

                      <div
                        style={{ backgroundColor: "rgb(241, 187, 14)" }}
                        className="progressBar mx-md-4 mx-2 flex-s-c"
                      >
                        <div
                          className="van-progress"
                          style={{
                            backgroundColor: "rgb(241, 187, 14)",
                            height: "3px",
                            width: "100%",
                          }}
                        >
                          <span
                            className="van-progress__portion"
                            style={{
                              width: "100%",
                              backgroundColor: "rgb(241, 187, 14)",
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="mx-md-4 mx-2 mt-4">
                        <h5
                          className="float-start"
                          style={{
                            color: "rgb(188, 177, 177)",
                            fontWeight: 600,
                          }}
                        >
                          {each.minimum_amount} USD
                        </h5>
                        <h5
                          className="float-end"
                          style={{
                            color: "rgb(188, 177, 177)",
                            fontWeight: 600,
                          }}
                        >
                          {each.maximum_amount} USD
                        </h5>
                      </div>

                      <button
                        className="w-100 currentLevel mt-3 py-2 border-0 text-white"
                        style={{ bottom: "0", backgroundColor: "black" }}
                      >
                        {each.id == userData.vip_level ? (
                          <h4 style={{ fontWeight: 600 }}>
                            {t("currentVipLevel")}
                          </h4>
                        ) : (
                          <h4 style={{ fontWeight: 600 }}>
                            {t("activateNow")}{" "}
                          </h4>
                        )}
                      </button>
                    </div>

                    <br />

                    <div
                      className="ms-md-5 pt-2 mt-md-5 ms-3 mt-2 mb-4 text-white"
                      style={{
                        height: "150px", // Set a fixed height
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Optional: show ellipsis
                        display: "-webkit-box", // For ellipsis
                        WebkitLineClamp: 3, // Limit to 3 lines
                        WebkitBoxOrient: "vertical", // Required for line clamping
                        color: "white", // Text color
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: each.description }}
                      />
                      {/* {each.description} */}
                    </div>

                    <br className="d-md-none d-block" />
                    <br className="d-md-none d-block" />
                    <div className="mt-5">
                      <button
                        className="w-100 mb-4 py-md-3 py-2 border-0 text-white"
                        style={{
                          backgroundColor: "rgb(241, 187, 14)",
                          borderRadius: "5px",
                        }}
                      >
                        {each.id == userData.vip_level ? (
                          <div style={{ color: "black" }}>
                            {t("alreadyActivated")}
                          </div>
                        ) : (
                          <div style={{ color: "black" }}>
                            {t("activateNow")}
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="" style={{ height: "80vh" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipLevelPage;
