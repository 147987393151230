import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const CustomerServicePage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const [mockData, setMockData] = useState([]);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const domain = "https://admin.amzscoutmembers.com/";
  const fetchData = async () => {
    try {
      const response = await axios.get(`${domain}api/directCustomerService`);
      console.log(response.data);
      if (response.data.status == "true") {
        setMockData(response.data.mockData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div className="loginBody">
      <style>
        {`    .text{
font-size: 1rem
    }
.myImg{
    height: 200px;
}
@media (max-width: 600px) {
.text{
    font-size:.7rem;
}
.myImg{
 height: 100px;
}
}`}
      </style>
      <div className="loginContainer" style={{ backgroundColor: "white" }}>
        <div
          className="d-flex py-2 position-sticky"
          style={{ top: 0, backgroundColor: "black" }}
        >
          <div
            id="backBtn"
            onClick={goBack}
            style={{ color: "white", cursor: "pointer" }}
          >
            <div className="ms-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "25px", height: "25px" }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="m-0 p-0 text-white font-weight-bold text-center col-11 mt-1"
            style={{ fontSize: ".9rem" }}
          >
            {t("customerService")}
          </h5>
        </div>
        <div
          className="w-100 px-3 py-2"
          style={{ backgroundColor: "rgb(191, 249, 239)" }}
        >
          {t("customerServiceDesc")}
        </div>
        <div className="mt-3 px-md-3 px-0 py-3">
          <div className="px-md-2 px-0 boxContainer">
            <div className="p-0 m-0 col-12 row">
              {mockData.map((each) => (
                <div
                  key={each.id}
                  className="col-md-5 offset-md-1 offset-0 col-6 mt-3 mx-md-4 px-md-2 d-flex justify-content-center"
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      width: "100%",
                      backgroundColor: "white",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    }}
                    className="px-0 py-0 mx-0 m-0 eachCard rounded-2"
                  >
                    <div className="w-100 mx-0 my-2 px-0">
                      <h6 className="mx-0 w-100 text col-12 text-center text-black">
                        {each.name}
                      </h6>
                    </div>
                    <img
                      className="myImg"
                      src={`${domain}storage/app/public/${encodeURI(each.img)}`}
                      style={{ width: "100%" }}
                      alt=""
                    />
                    <div className="mb-0 pb-0 mt-3">
                      <div className="my-0 py-0 d-flex justify-content-center">
                        <a
                          href={each.link}
                          type="button"
                          className="d-flex justify-content-center w-md-50 w-100 px-2 py-1 text-decoration-none"
                          style={{
                            color: "white",
                            backgroundColor: "rgba(14, 183, 14, 0.873)",
                            borderRadius: "3px",
                            textDecoration: "none",
                          }}
                        >
                          <div>
                            <i className="text-white fas fa-phone"></i>{" "}
                            {t("contactNow")}
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="" style={{ height: "80vh" }}></div>
    </div>
  );
};

export default CustomerServicePage;
