import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userName: "Kaung Khant Soe",
  data: {},
  time: "",
  commision: "",
  userData: {},
  orderCount: {},
  imgArray: {},
  firstBasicInfoArray: [],
  secondBasicInfoArray: [],
  productInfoArray: [],
  orderId: "",
};
const counterSlice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    updateOrder: (state, action) => {
      //   console.log(action, "is action");
      state.data = action.payload.data;
      state.time = action.payload.time;
      state.commision = action.payload.commision;
      state.userData = action.payload.userData;
      state.orderCount = action.payload.orderCount;
      state.imgArray = action.payload.imgArray;
      state.orderId = action.payload.orderId;
      state.firstBasicInfoArray = action.payload.firstBasicInfoArray;
      state.secondBasicInfoArray = action.payload.secondBasicInfoArray;
      state.productInfoArray = action.payload.productInfoArray;
    },
    deleteOrder: (state, action) => {
      state.data = {};
      state.time = "";
      state.commision = "";
      state.userData = {};
      state.orderCount = "";
      state.imgArray = [];
      state.orderId = "";
      state.firstBasicInfoArray = [];
      state.secondBasicInfoArray = [];
      state.productInfoArray = [];
    },
  },
});
export const { updateOrder, deleteOrder } = counterSlice.actions;
export default counterSlice.reducer;
