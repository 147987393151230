import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { id, en };
const TeamRevenuePage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [isUserToday, setIsUserToday] = useState(false);
  const myUserData = JSON.parse(getUser());
  const token = getToken();
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };
  const navigate = useNavigate();
  // Mock data
  const [userData, setUserData] = useState({
    wallet_balance: 0,
    todayPrice: 0,
    todayTeam: 0,
    totalPrice: 0,
    totalTeam: 0,
  });

  const [lowLevelUsers, setLowLevelUsers] = useState([]);

  const [todayLowLevelUsers, setTodayLowLevelUsers] = useState([]);

  const [displayToday, setDisplayToday] = useState(true);
  const [displayFirst, setDisplayFirst] = useState(true);

  const handleToCommisionStatement = () => {
    // history.push("/user/commisionStatement");
  };

  const handleTotalClick = () => {
    setDisplayToday(false);
    setDisplayFirst(true);
  };

  const handleTodayClick = () => {
    setDisplayToday(true);
    setDisplayFirst(false);
  };

  const handleFirstTotalClick = () => {
    setDisplayFirst(false);
  };

  const handleSecondTotalClick = () => {
    setDisplayFirst(true);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/teamRevenue",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setUserData(response.data.userData);
        setTodayLowLevelUsers(response.data.todayLowLevelUsers);
        setLowLevelUsers(response.data.lowLevelUsers);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div className="loginBody">
      <div
        className="loginContainer"
        style={{ overflow: "initial !important" }}
      >
        <div
          className="d-flex py-2 position-sticky"
          style={{ top: 0, zIndex: 4, backgroundColor: "black" }}
        >
          <div
            id="backBtn"
            onClick={() => {
              navigate(-1);
            }}
            style={{ color: "white", cursor: "pointer" }}
          >
            <div className="ms-2">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "21px", height: "21px" }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="text-white font-weight-bold text-center mt-1 col-11"
            style={{ fontSize: ".9rem" }}
          >
            {t("myTeam")}
          </h5>
        </div>

        <div className="mt-1 px-3 py-3">
          {/* big card */}
          <div className="mt-1 mx-3 homeBigCardContainer position-relative">
            <div
              className="py-4 px-4 homeBigCard"
              style={{
                width: "100%",
                backgroundColor: "black",
                borderRadius: "10px",
              }}
            >
              <h6 className="text-white">{t("walletAccont")}</h6>
              <div className="d-flex text-white">
                <h3 style={{ fontStyle: "italic", fontWeight: 700 }}>
                  {userData.wallet_balance}
                </h3>
                <div className="mt-2">USD</div>
              </div>

              <div className="mt-2 homeBtnContainer">
                <button
                  onClick={() => {
                    todayClicked();
                  }}
                  id="firstToday"
                  className="px-1"
                  style={{
                    backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                    color: isToday ? "black" : "white",
                    borderRadius: "3px",
                    paddingTop: "2px",
                    border: isToday ? "none" : "white solid .4px",
                    paddingBottom: "2px",

                    fontSize: ".8rem",
                    outline: "none",
                  }}
                >
                  {t("todayEarnings")}
                </button>
                <button
                  id="firstTotal"
                  onClick={() => {
                    totalClicked();
                  }}
                  className="ms-3 px-1  "
                  style={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: ".8rem",
                    outline: "none",
                    border: isTotal ? "none" : "white solid .4px",
                    backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                    color: isTotal ? "black" : "white",
                    borderRadius: "3px",
                  }}
                >
                  {t("totalEarnings")}
                </button>
              </div>

              <div className="homeCasesContainer d-flex mt-md-2 mt-2">
                <div
                  id="firstOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("orderRevenue")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayPrice.toFixed(3)
                      : userData.totalPrice.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("team")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayTeam.toFixed(3)
                      : userData.totalTeam.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstEarning"
                  style={{ display: "inline" }}
                  className="totalContainer me-3 pe-2"
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("totalEarnings")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? (userData.todayPrice + userData.todayTeam).toFixed(3)
                      : (userData.totalPrice + userData.totalTeam).toFixed(3)}
                  </div>
                </div>
                <div
                  id="secondOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("orderRevenue")}
                  </div>
                  <div className="text-white">345.2</div>
                </div>
                <div
                  id="secondTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("team")}
                  </div>
                  <div className="text-white">345.23</div>
                </div>
                <div
                  id="secondEarning"
                  className="totalContainer me-3 pe-2"
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("totalEarnings")}
                  </div>
                  <div className="text-white">342.22</div>
                </div>
              </div>

              <button
                id="ToCommisionStatement"
                className="position-absolute me-4 mt-3"
                style={{
                  outline: "none",
                  cursor: "pointer",
                  top: 0,
                  right: 0,
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "rgb(241, 187, 14)",
                }}
                onClick={() => {
                  navigate("/commisionStatement");
                }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/descImg.png`}
                  style={{ width: "35px", height: "35px" }}
                  alt=""
                />
                <span style={{ color: "black", fontWeight: 600 }}>
                  {t("teamDescription")}
                </span>
              </button>
            </div>
          </div>

          <div
            style={{
              marginTop: "1rem",
              marginBottom: "1.25rem",
              backgroundColor: "white",
            }}
          >
            <div className=" float-start">
              {/* <img src="/icons/No need to edit/book.png" style={{ width: '30px', height: '30px', borderRadius: '6px' }} alt="" /> */}
              <div
                style={{
                  display: "inline",
                  marginLeft: "1rem",
                  fontWeight: 690,
                  color: "black",
                }}
              >
                {t("friendsIInvited")} :
                <span
                  className=" ml-1"
                  style={{
                    padding: "0 0.5rem",
                    borderRadius: "50%",
                    width: "6px",
                    backgroundColor: "rgb(241, 187, 14)",
                    color: "black",
                  }}
                >
                  {lowLevelUsers.length}
                </span>
              </div>
            </div>
            <div
              className=" float-end"
              style={{ display: "flex", color: "black" }}
            >
              <div
                style={{
                  display: "inline",
                  marginRight: "1rem",
                  fontWeight: 690,
                }}
              >
                {t("team")}
              </div>
              <div
                style={{
                  display: "flex",
                  borderRadius: "14px",
                  backgroundColor: "rgb(166, 169, 171)",
                }}
              >
                <div
                  id="total"
                  onClick={() => {
                    setIsUserToday(false);
                  }}
                  style={{
                    width: "50%",
                    padding: "0 0.5rem",
                    borderRadius: "10px",
                    backgroundColor: !isUserToday && "rgb(241, 187, 14)",
                  }}
                >
                  {t("total")}
                </div>
                <div
                  id="today"
                  onClick={() => {
                    setIsUserToday(true);
                  }}
                  style={{
                    padding: "0 0.5rem",
                    borderRadius: "10px",
                    backgroundColor: isUserToday && "rgb(241, 187, 14)",
                  }}
                >
                  {t("today")}
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          {!isUserToday && (
            <div>
              {lowLevelUsers.map((item, index) => (
                <div style={{ margin: "0.5rem 0", width: "100%" }}>
                  <div className=" float-start" style={{ display: "flex" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                      style={{ width: "45px", height: "45px" }}
                      alt=""
                    />
                    <div style={{ marginLeft: "1rem" }}>
                      <div style={{ color: "black", fontWeight: 500 }}>
                        {item.name}
                      </div>
                      <small>{item.phone}</small>
                    </div>
                  </div>
                  <div
                    className=" float-end"
                    style={{ color: "black", fontWeight: 560 }}
                  >
                    {item.wallet_balance} USD
                  </div>
                  <br />
                  <br />
                </div>
              ))}
            </div>
          )}
          {isUserToday && (
            <div>
              {todayLowLevelUsers.map((item, index) => (
                <div style={{ margin: "0.5rem 0", width: "100%" }}>
                  <div className=" float-start" style={{ display: "flex" }}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
                      style={{ width: "45px", height: "45px" }}
                      alt=""
                    />
                    <div style={{ marginLeft: "1rem" }}>
                      <div style={{ color: "black", fontWeight: 500 }}>
                        {item.name}
                      </div>
                      <small>{item.phone}</small>
                    </div>
                  </div>
                  <div
                    className=" float-end"
                    style={{ color: "black", fontWeight: 560 }}
                  >
                    {item.wallet_balance} USD
                  </div>
                  <br />
                  <br />
                </div>
              ))}
            </div>
          )}
          <div className="" style={{ height: "80vh" }}></div>
        </div>
      </div>
    </div>
  );
};

export default TeamRevenuePage;
