import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const MyNoticePage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [context, setContext] = useState("");
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/note"
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setContext(response.data.context);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        navigate("/login");
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);
  return (
    <div
      className="loginBody   position-relative   "
      style={{ overflow: "initial !important", backgroundColor: "#f9f9f9" }}
    >
      <div
        className="loginContainer  position-relative top-0"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div style={{}} className="d-flex py-2   bg-black top-0">
          <div
            id="backBtn"
            style={{ color: "white", cursor: "pointer" }}
            className="back-btn"
          >
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                className="back-img"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
                alt="back"
              />
            </div>
          </div>
          <h5
            style={{ fontSize: "1rem" }}
            className="text-white font-weight-bold text-center col-11 mt-1 title"
          >
            {t("notice")}
          </h5>
        </div>

        <div className=" bg-white mt-3 px-3 py-3">
          {context === "" ? (
            <div
              style={{ color: "rgb(149, 144, 144)" }}
              className=" text-center"
            >
              No More Data ...
            </div>
          ) : (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: context,
              }}
              style={{ marginTop: "1rem" }}
            ></div>
          )}
        </div>

        <div className="" style={{ height: "60vh" }}></div>
      </div>
    </div>
  );
};
export default MyNoticePage;
