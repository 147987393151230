import React, { useState, useEffect } from "react";
// import "./register.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import axios from "axios";
import { getToken, getUser } from "../utils/localStorage";
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { en, id };
const RegisterPage = () => {
  const [language, setLanguage] = useState("id"); // Default language is English

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    transactionPassword: "",
    password: "",
    confirmPassword: "",
    inviteCode: "",
    gender: "",
    IP: "",
  });

  const [errors, setErrors] = useState({});
  const [policyChecked, setPolicyChecked] = useState(false);
  const [falseInviteCode, setFalseInviteCode] = useState(false);
  const [existedUser, setExisteduser] = useState(false);

  const togglePasswordVisibility = (type, id) => {
    const input = document.getElementById(type);

    const img = document.getElementById(id);

    if (input.type === "password") {
      input.type = "text";
      img.src = `${process.env.PUBLIC_URL}/images/No need to edit/openEye.png`;
    } else {
      input.type = "password";

      img.src = `${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    const token = getToken();
    const user = getUser();
    if (token && user) {
      navigate("/");
    }
    async function getIPAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        const ip = data.ip;

        const locationResponse = await fetch(`https://ipapi.co/${ip}/json/`);
        const locationData = await locationResponse.json();

        setFormData((prevData) => ({
          ...prevData,
          IP: `${ip} : ${locationData.city}, ${locationData.country_name}`,
        }));
      } catch (error) {
        console.error("Error fetching IP address or location:", error);
      }
    }
    getIPAddress();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePolicyChange = () => {
    setPolicyChecked(!policyChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (!formData.name) validationErrors.name = t("requireUsername");
    if (!formData.phone) validationErrors.phone = t("requirePhone");
    if (!formData.password) validationErrors.password = t("requirePassword");
    if (!formData.confirmPassword)
      validationErrors.confirmPassword = t("requireConfirmPassword");
    if (!formData.transactionPassword)
      validationErrors.transactionPassword = t("requireTransactionPassword");
    if (!formData.inviteCode)
      validationErrors.inviteCode = t("requireInviteCode");
    if (formData.password && formData.password !== formData.confirmPassword)
      validationErrors.differentPassword = t("requireMustSame");
    if (formData.password && formData.password.length < 6)
      validationErrors.shortPassword = "Password must be at least 6 characters";
    if (formData.transactionPassword && formData.transactionPassword.length < 6)
      validationErrors.shortTransactionPassword = t("shortPassword");

    if (formData.password && formData.password === formData.transactionPassword)
      validationErrors.samePassword = t("mustNotSame");

    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      // If there are errors, return early and do not proceed with the API request
      return;
    }

    // If no errors, proceed with the API request
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/register",
        formData
      );
      console.log(response.data);
      setIsLoading(false);
      if (response.data.status == "true") {
        navigate("/login");
      }
      if (
        response.data.status == "error" &&
        response.data.data == "existedUser"
      ) {
        setExisteduser(true);
        setTimeout(() => {
          setExisteduser(false);
        }, 2000);
      }
      if (
        response.data.status == "error" &&
        response.data.data == "falseInviteCode"
      ) {
        setFalseInviteCode(true);
        setTimeout(() => {
          setFalseInviteCode(false);
        }, 2000);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle any errors that occur during the API request
      console.error("There was an registering in:", error);
    }
  };

  return (
    <div className="registerBody">
      <style>
        {`
        .notification{ position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;}`}
      </style>
      <form
        action="/register"
        method="POST"
        className="registerContainer"
        style={{ backgroundColor: "white" }}
      >
        <div className="d-flex py-2" style={{ backgroundColor: "black" }}>
          <div id="backBtn" style={{ color: "white", cursor: "pointer" }}>
            <div className="ms-3 text-white ">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{
                  width: "25px",
                  height: "25px",
                  color: "white",
                }}
                className=" bg-black"
                alt="back"
              />
            </div>
          </div>
          <h5 className="text-white font-weight-bold text-center col-11">
            Register
          </h5>
        </div>
        <div className="registerLogoContainer py-3">
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/js.png`}
            style={{ width: "150px", height: "100px" }}
            alt="logo"
          />
        </div>
        <div
          className="registerCard py-4 px-4"
          style={{ backgroundColor: "white" }}
        >
          <div className="pb-3">
            <h5 className="float-start semibold">{t("userName")}</h5>
            <input
              type="text"
              id="name"
              value={formData.name}
              className="float-end border-0"
              name="name"
              onChange={handleChange}
              placeholder={t("userName")}
              style={{ textAlign: "right" }}
            />
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          {errors.name && <small className="text-danger">{errors.name}</small>}

          <div className="mt-4 pb-3">
            <h5 className="float-start font-weight-bolder semibold">Phone</h5>
            <input
              type="text"
              id="phone"
              value={formData.phone}
              className="float-end border-0"
              name="phone"
              onChange={handleChange}
              placeholder="Phone"
              style={{ textAlign: "right" }}
            />
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          {errors.phone && (
            <small className="text-danger">{errors.phone}</small>
          )}

          <div className="mt-4 pb-3">
            <h5 className="m-0 font-weight-bolder semibold">
              {t("transactionPassword")}
            </h5>
            <br />
            <div className="bg-danger w-100">
              <input
                id="transactionPassword"
                type="password"
                className="float-start border-0"
                value={formData.transactionPassword}
                name="transactionPassword"
                onChange={handleChange}
                placeholder={t("transactionPassword")}
              />
              <img
                id="togglePasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye me-3 float-end"
                alt="toggle"
                onClick={() =>
                  togglePasswordVisibility(
                    "transactionPassword",
                    "togglePasswordBtn"
                  )
                }
              />
            </div>
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          {errors.transactionPassword && (
            <small className="text-danger">{errors.transactionPassword}</small>
          )}

          <div className="mt-4 pb-3">
            <h5 className="m-0 font-weight-bolder semibold">{t("password")}</h5>
            <br />
            <div className="bg-danger w-100">
              <input
                id="password"
                type="password"
                className="float-start border-0"
                value={formData.password}
                name="password"
                onChange={handleChange}
                placeholder="Password"
              />
              <img
                id="toggleNewPasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye me-3 float-end"
                onClick={() =>
                  togglePasswordVisibility("password", "toggleNewPasswordBtn")
                }
                alt="toggle"
              />
            </div>
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          {errors.password && (
            <small className="text-danger">{errors.password}</small>
          )}
          {errors.shortPassword && (
            <small className="text-danger">{errors.shortPassword}</small>
          )}
          {errors.differentPassword && (
            <small className="text-danger">{errors.differentPassword}</small>
          )}

          <div className="mt-4 pb-3">
            <h5 className="m-0 font-weight-bolder semibold">
              {t("confirmPassword")}
            </h5>
            <br />
            <div className="bg-danger w-100">
              <input
                id="confirmPassword"
                type="password"
                className="float-start border-0"
                value={formData.confirmPassword}
                name="confirmPassword"
                onChange={handleChange}
                placeholder={t("confirmPassword")}
              />
              <img
                id="toggleConfirmPasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye me-3 float-end"
                onClick={() =>
                  togglePasswordVisibility(
                    "confirmPassword",
                    "toggleConfirmPasswordBtn"
                  )
                }
                alt="toggle"
              />
            </div>
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          {errors.confirmPassword && (
            <small className="text-danger">{errors.confirmPassword}</small>
          )}
          <div className="mt-4 pb-3">
            <h5 className="float-start font-weight-bolder semibold">
              {t("gender")}
            </h5>
            <div className="float-end border-0">
              <div className="form-check form-check-inline">
                <input
                  className="me-2"
                  type="radio"
                  name="gender"
                  id="inlineRadio1"
                  value="male"
                  onChange={handleChange}
                  style={{ width: "20px", height: "20px" }}
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  {t("male")}
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="me-2"
                  type="radio"
                  name="gender"
                  id="inlineRadio2"
                  value="female"
                  onChange={handleChange}
                  style={{ width: "20px", height: "20px" }}
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  {t("female")}
                </label>
              </div>
            </div>
          </div>
          <hr className="mt-4 " style={{ color: "rgb(158, 156, 156)" }} />
          <div className="mt-4 pb-3">
            <h5
              className="float-start font-weight-bolder semibold"
              style={{ color: "rgb(241, 187, 14)" }}
            >
              {t("inviteCode")}
            </h5>
            <input
              type="text"
              id="inviteCode"
              className="float-end border-0"
              style={{ textAlign: "right" }}
              name="inviteCode"
              value={formData.inviteCode}
              onChange={handleChange}
              placeholder="Invite Code"
            />
          </div>
          <br />
          {errors.inviteCode && (
            <small className="text-danger">{errors.inviteCode}</small>
          )}
          <div className="mt-4 d-flex justify-content-center form-check">
            <div>
              <input
                className="  form-check-input me-3 "
                style={{ width: "20px", height: "20px" }}
                type="checkbox"
                value={policyChecked}
                id="flexCheckDefault"
                onChange={handlePolicyChange}
              />
              <label
                className="form-check-label ms-2 text-dark"
                htmlFor="flexCheckDefault"
              >
                {t("agreeToUseTas")}
              </label>
            </div>
          </div>
          <div className="registerSubmit mt-4">
            {isLoading ? (
              <button
                type="submit"
                id="submit"
                className="btn btn-block text-light"
                style={{ backgroundColor: "black" }}
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                id="submit"
                className="btn btn-block text-light"
                style={{ backgroundColor: "black" }}
                onClick={handleSubmit}
              >
                <h5> Register</h5>
              </button>
            )}
            <button type="submit" id="realSubmit" className="d-none" />
          </div>
        </div>
      </form>
      {falseInviteCode && (
        <div
          id="incorrectPassword"
          className="notification"
          style={{ display: "flex" }}
        >
          {t("noInviteCode")}
        </div>
      )}

      {existedUser && (
        <div
          id="incorrectPassword"
          className="notification"
          style={{ display: "flex" }}
        >
          {t("existedUser")}
        </div>
      )}
    </div>
  );
};

export default RegisterPage;
