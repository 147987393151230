import React, { useEffect, useState } from "react";
import bcrypt from "bcryptjs";
import { json, useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
  setUser,
} from "../utils/localStorage";
import axios from "axios";
import en from "../translations/en.json";
import id from "../translations/id.json";
// import "./dashboard.css";
const translations = { en, id };
const UpdatePasswordPage = () => {
  const [language, setLanguage] = useState("id");
  const [isLoading, setIsLoading] = useState(false);
  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const userData = JSON.parse(getUser());
  const [password, setPassword] = useState(userData.transactionPassword);
  const token = getToken();

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
  }, []);

  const [errors, setErrors] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
    differentPassword: false,
    shortPassword: false,
  });
  const [falsePassword, setFalsePassword] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [oldPasswordIncorrect, setOldPasswordIncorrect] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  function checkPassword(plainPassword, hashedPassword) {
    return bcrypt.compareSync(plainPassword, hashedPassword);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newErrors = {
      oldPassword: !oldPassword,
      newPassword: !newPassword,
      confirmPassword: !confirmPassword,
      differentPassword: newPassword !== confirmPassword,
      shortPassword: newPassword.length < 6,
    };

    setErrors(newErrors);

    if (!Object.values(newErrors).some((error) => error)) {
      if (checkPassword(oldPassword, password)) {
        try {
          const response = await axios.post(
            "https://admin.amzscoutmembers.com/api/changeTransactionPassword",
            {
              id: userData.id,
              newPassword: newPassword,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.data.status == "true") {
            setIsLoading(false);
            setPassword(response.data.password);
            setUser(JSON.stringify(response.data.userData));
            setIsUpdated(true);
            setTimeout(() => {
              setIsUpdated(false);
            }, 1234);
          }
        } catch (error) {
          console.log(error);
          setIsLoading(false);
          if (error.message == "Request failed with status code 401") {
            removeToken();
            removeUser();
            navigate("/login");
          }
        }
      } else {
        setFalsePassword(true);
        setTimeout(() => {
          setFalsePassword(false);
        }, 1230);
        return;
      }
    }
  };

  // const togglePasswordVisibility = (id) => {
  //   const input = document.getElementById(id);
  //   input.type = input.type === "password" ? "text" : "password";
  // };

  const togglePasswordVisibility = (type) => {
    const input = document.getElementById(type);
    const img = document.getElementById(
      `toggle${type.charAt(0).toUpperCase() + type.slice(1)}Btn`
    );

    if (input.type === "password") {
      input.type = "text";
      img.src = `${process.env.PUBLIC_URL}/images/No need to edit/openEye.png`;
    } else {
      input.type = "password";

      img.src = `${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`;
    }
  };

  return (
    <div className="loginBody">
      <style>
        {`
         .spinner {
  width: 30px;
  height: 30px;
  border: 4px solid black;
  border-left-color: rgb(241, 187, 14); /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 1px auto;
}
    .notification {
      opacity: 0.6;
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #333;
      color: #fff;
      padding: 15px;
      border-radius: 5px;
      z-index: 1000;
    }
    .firstTime-modal {
      display: block;
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgba(0,0,0,0.4);
    }
    .firstTime-modal-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;
      max-width: 500px;
      text-align: center;
      position: relative;
    }
    .firstTime-close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .firstTime-close:hover,
    .firstTime-close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
    .firstTime-confirm-btn {
      background-color: black;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 3px;
      cursor: pointer;
      margin-top: 20px;
    }
    .firstTime-confirm-btn:hover {
      background-color: #333;
    }
  `}
      </style>
      <div className="loginContainer" style={{ backgroundColor: "#f9f9f9" }}>
        <div
          className="d-flex py-2 position-sticky"
          style={{ top: 0, backgroundColor: "black" }}
        >
          <div
            id="backBtn"
            onClick={() => {
              navigate(-1);
            }}
            style={{ color: "white", cursor: "pointer" }}
          >
            <div className="ms-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: 25, height: 25 }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="m-0 p-0 text-white font-weight-bold text-center col-11 mt-1"
            style={{ fontSize: ".9rem" }}
          >
            {t("updateTransactionPassword")}
          </h5>
        </div>

        <form className="mt-3 py-3 px-3" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="semibold mb-3 ms-5" htmlFor="oldPassword">
              {t("oldPassword")}
            </label>
            <div className="mx-4 mb-1 px-4 py-md-4 py-2 updateInputContainer">
              <input
                name="oldPassword"
                id="oldPassword"
                type="password"
                className="ps-2 w-75 updateInput border-0 password-input"
                placeholder={t("oldPassword")}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <img
                id="toggleOldPasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye float-end"
                alt=""
                onClick={() => togglePasswordVisibility("oldPassword")}
              />
            </div>
            {errors.oldPassword && (
              <small id="errorOldPassword" className="ms-5 text-danger">
                {t("requireOldPassword")}
              </small>
            )}
          </div>

          <div className="mb-3">
            <label className="semibold mb-3 ms-5" htmlFor="newPassword">
              {t("newPassword")}
            </label>
            <div className="mx-4 mb-1  px-4 py-md-4 py-2 updateInputContainer">
              <input
                name="newPassword"
                id="newPassword"
                type="password"
                className="ps-2 w-75 updateInput border-0 password-input"
                placeholder={t("newPassword")}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <img
                id="toggleNewPasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye float-end"
                alt=""
                onClick={() => togglePasswordVisibility("newPassword")}
              />
            </div>
            {errors.newPassword && (
              <small id="errorNewPassword" className="ms-5 text-danger">
                {t("requireNewPassword")}
              </small>
            )}
            {errors.differentPassword && (
              <small id="errorDifferentPassword" className="ms-5 text-danger">
                {t("mustSame")}
              </small>
            )}
            {errors.shortPassword && (
              <small id="shortPassword" className="text-danger ms-5">
                {t("shortPassword")}
              </small>
            )}
          </div>

          <div className="mb-3">
            <label className="semibold mb-3 ms-5" htmlFor="confirmPassword">
              {t("confirmPassword")}
            </label>
            <div className="mx-4 mb-1  px-4 py-md-4 py-2 updateInputContainer">
              <input
                name="confirmPassword"
                id="confirmPassword"
                type="password"
                className="ps-2 w-75 updateInput border-0 password-input"
                placeholder={t("confirmPassword")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <img
                id="toggleConfirmPasswordBtn"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/closedEye.png`}
                className="eye float-end"
                alt=""
                onClick={() => togglePasswordVisibility("confirmPassword")}
              />
            </div>
            {errors.confirmPassword && (
              <small id="errorConfirmPassword" className="ms-5 text-danger">
                {t("requireConfirmNewPassword")}
              </small>
            )}
          </div>

          <button type="submit" hidden id="realSubmit">
            {t("post")}
          </button>
          {isLoading ? (
            <button
              type="submit"
              id="submit"
              className=" text-light mx-2 mx-md-5 my-5 py-md-3 py-2"
              style={{
                cursor: "pointer",
                width: "90%",
                boxShadow: "none",
                outline: "none",
                backgroundColor: "rgb(241, 187, 14)",
                borderRadius: 5,
                border: "none",
              }}
            >
              <div
                className="spinner text-black"
                style={{ color: "black" }}
              ></div>
            </button>
          ) : (
            <button
              type="button"
              id="fakeSubmit"
              className="mx-2 mx-md-5 my-5 py-md-3 py-2"
              style={{
                cursor: "pointer",
                width: "90%",
                boxShadow: "none",
                outline: "none",
                backgroundColor: "rgb(241, 187, 14)",
                borderRadius: 5,
                border: "none",
              }}
              onClick={handleSubmit}
            >
              {t("update")}
            </button>
          )}
        </form>

        <div
          id="passwordUpdated"
          className="notification"
          style={{ display: passwordUpdated ? "block" : "none" }}
        >
          {t("successfullyUpdatedPassword")}
        </div>
        <div
          id="oldPassword"
          className="notification"
          style={{ display: oldPasswordIncorrect ? "block" : "none" }}
        >
          {t("incorrectOldPassword")}
        </div>
      </div>

      <div className="" style={{ height: "20vh" }}></div>
      {falsePassword && (
        <div id="incorrectTransactionPassword" class="notification d-flex ">
          {t("incorrectOldPassword")}
        </div>
      )}

      {isUpdated && (
        <div id="incorrectTransactionPassword" class="notification d-flex ">
          {t("successfullyUpdatedPassword")}
        </div>
      )}
    </div>
  );
};

export default UpdatePasswordPage;
