import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import id from "../translations/id.json";
import en from "../translations/en.json";

import axios from "axios";
import { updateOrder } from "../features/counterSlice";
import { useDispatch } from "react-redux";
// import './StartingPage.css'; // Assuming you have a separate CSS file for additional styles
// useState Declaration
const translations = { en, id };
const StartingPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(getUser());
  const token = getToken();
  const [isGenerateOrder, setIsGenerateOrder] = useState(false);
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const [wallet, setWallet] = useState("0");
  const [todayPrice, setTodayPrice] = useState(0);
  const [todayTeam, setTodayTeam] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTeam, setTotalTeam] = useState(0);
  const [levelImg, setLevelImg] = useState("");
  const [unfinishedError, setUnfinishedError] = useState(false);
  const [insufficientError, setInsufficientError] = useState(false);
  const [filledError, setFilledError] = useState(false);
  const [noOrder, setNoOrder] = useState(false);
  const [levelName, setLevelName] = useState("VIP 1");
  const [commision, setCommision] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalIndex, setTotalIndex] = useState(0);
  const adminDomain = "https://admin.amzscoutmembers.com/";
  const domain = "https://amzscoutmembers.com/";
  const claimGift = async () => {
    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/getGift",
        {
          id: userData.id,
          giftId: giftId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        console.log(response.data.status);

        closeModal();
        navigate("/messages");
      }
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };

  const getOrder = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/order",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        console.log(response.data);
        setIsGenerateOrder(false);
        if (response.data.status == "false") {
          if (response.data.data == "You have still unfinished Orders") {
            setUnfinishedError(true);
            setTimeout(() => {
              setUnfinishedError(false);
            }, 2300);
          }
          if (response.data.data == "No Order Generated") {
            setNoOrder(true);
            setTimeout(() => {
              setNoOrder(false);
            }, 2300);
          }
          if (response.data.data == "Your Today's task number is full") {
            setFilledError(true);
            setTimeout(() => {
              setFilledError(false);
            }, 2300);
          }
        }
        if (response.data.status == "true") {
          if (response.data.isGift == "true") {
            setModalOpen(true);
            setGiftId(response.data.gift.id);
            setAmount(response.data.gift.amount);
            return;
          }
          dispatch(updateOrder(response.data));
          navigate("/order");
        }
      }
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/starting",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setCurrentIndex(response.data.data.currentTaskNumber);
      setTotalIndex(response.data.data.orderNumberByDay);
      setCommision(response.data.data.memberCommision);
      setLevelName(response.data.data.memberLevelName);
      setInviteCode(response.data.data.invite_code);
      setWallet(response.data.data.wallet_balance);
      setTodayPrice(response.data.data.todayPrice);
      setTodayTeam(response.data.data.todayTeam);
      setTotalPrice(response.data.data.totalPrice);
      setTotalTeam(response.data.data.totalTeam);
      setLevelImg(response.data.data.levelImg);
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [giftId, setGiftId] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div className="ps-3 my-3">
      <style>
        {`
        .modal{
       width: 100%;
        }
        .notification {
        opacity: 0.7;
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;
    }
        @media(max-width: 1300px){

.modal{
 width: "85%"
}
        }
 `}
      </style>

      {/* Profile Header */}
      <div className="profilesHeader">
        <div className="ms-md-5 ms-2 ps-md-3 ps-1 d-flex">
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
            style={{ width: "60px", height: "60px" }}
            alt=""
          />
          <div>
            <div className="d-block ms-2 profileTextContainer">
              <div className="d-flex">
                <h5
                  style={{ color: "black", fontWeight: 650, fontSize: ".9rem" }}
                >
                  {userData.name}
                </h5>
                <img
                  src={
                    levelImg == ""
                      ? `${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`
                      : `${adminDomain}storage/app/public/${encodeURI(
                          levelImg
                        )}`
                  }
                  // src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
                  style={{ width: "24px", height: "24px" }}
                  className="ms-2"
                  alt=""
                />
              </div>
            </div>
            <div className="w-100 ms-2">
              <h6
                className="w-100 d-flex"
                style={{ color: "black", fontWeight: 700 }}
              >
                {t("creditScore")}:
                <div
                  className="mt-2 ms-2"
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    height: "1px",
                    width: `${userData.reputation_score}px`,
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                ></div>
                <div style={{ color: "black", fontWeight: 700 }}>
                  {userData.reputation_score}%
                </div>
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* Wallet Account Section */}
      <div className=" " style={{ width: "98%" }}>
        <div className="mt-3 mx-md-3 mx-1  homeBigCardContainer">
          <div
            className="py-3 px-md-3 px-2 homeBigCard"
            style={{
              width: "100%",
              backgroundColor: "black",
              borderRadius: "10px",
            }}
          >
            <h6 style={{ fontWeight: 600 }} className="my-0 py-0 text-white">
              {t("walletAccount")}
            </h6>
            <div className="d-flex text-white">
              <h3
                style={{ fontStyle: "italic", fontWeight: 700 }}
                className="mt-2"
              >
                {wallet}
              </h3>
              <div className="mt-3 pt-1" style={{ fontSize: ".8rem" }}>
                USD
              </div>
            </div>

            <div className="mt-2 homeBtnContainer">
              <button
                onClick={() => {
                  todayClicked();
                }}
                id="firstToday"
                className="px-1"
                style={{
                  backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                  color: isToday ? "black" : "white",
                  borderRadius: "3px",
                  paddingTop: "2px",
                  border: isToday ? "none" : "white solid .4px",
                  paddingBottom: "2px",

                  fontSize: ".8rem",
                  outline: "none",
                }}
              >
                {t("todayEarnings")}
              </button>
              <button
                id="firstTotal"
                onClick={() => {
                  totalClicked();
                }}
                className="ms-3 px-1  "
                style={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  fontSize: ".8rem",
                  outline: "none",
                  border: isTotal ? "none" : "white solid .4px",
                  backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                  color: isTotal ? "black" : "white",
                  borderRadius: "3px",
                }}
              >
                {t("totalEarnings")}
              </button>
            </div>

            <div className="homeCasesContainer d-flex mt-md-2 mt-2">
              <div
                id="firstOrder"
                className="revenueContainer me-3 pe-2"
                style={{
                  display: "inline",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("orederRevenue")}
                </div>
                <div className="text-white">
                  {" "}
                  {isToday ? todayPrice.toFixed(3) : totalPrice.toFixed(3)}{" "}
                </div>
              </div>
              <div
                id="firstTeam"
                className="teamContainer me-3 pe-2"
                style={{
                  display: "inline",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("team")}
                </div>
                <div className="text-white">
                  {" "}
                  {isToday ? todayTeam.toFixed(3) : totalTeam.toFixed(3)}{" "}
                </div>
              </div>
              <div
                id="firstEarning"
                style={{ display: "inline" }}
                className="totalContainer me-3 pe-2"
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("totalEarnings")}
                </div>
                <div className="text-white">
                  {" "}
                  {isToday
                    ? (todayTeam + todayPrice).toFixed(3)
                    : (totalPrice + totalTeam).toFixed(3)}{" "}
                </div>
              </div>
              <div
                id="secondOrder"
                className="revenueContainer me-3 pe-2"
                style={{
                  display: "none",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("orderRevenue")}
                </div>
                <div className="text-white">345.2</div>
              </div>
              <div
                id="secondTeam"
                className="teamContainer me-3 pe-2"
                style={{
                  display: "none",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("team")}
                </div>
                <div className="text-white">345.23</div>
              </div>
              <div
                id="secondEarning"
                className="totalContainer me-3 pe-2"
                style={{ display: "none" }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("totalEarnings")}
                </div>
                <div className="text-white">342.22</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="mx-2" style={{ width: "95%" }}>
        <div className="col-12 d-flex justify-content-between mt-4 row">
          <div className="col-6 bg-white py-3 d-flex justify-content-center">
            <a
              href="play"
              className="d-flex"
              style={{ textDecoration: "none" }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/1.png`}
                // src="/icons/No need to edit/1.png"
                style={{ width: "30px", height: "30px" }}
                alt=""
              />
              <small
                style={{ color: "black", fontWeight: 650 }}
                className="mt-2"
              >
                {t("orderDescription")}
              </small>
            </a>
          </div>
          <div className="col-5 ms-3 bg-white py-3 d-flex justify-content-center">
            <a
              href="notice"
              className="d-flex"
              style={{ textDecoration: "none" }}
            >
              <img
                // src="/icons/No need to edit/2.png"
                src={`${process.env.PUBLIC_URL}/images/No need to edit/2.png`}
                style={{ width: "30px", height: "30px" }}
                alt=""
              />
              <small
                style={{ color: "black", fontWeight: 650 }}
                className="mt-2"
              >
                {t("pleaseNote")}
              </small>
            </a>
          </div>
        </div>
      </div>

      {/* Notifications */}
      {insufficientError && (
        <div
          id="notification"
          className="notification"
          style={{
            fontSize: "1rem",
            display: "flex",
            color: "#fff",
            justifyContent: "center",
            fontWeight: 600,
          }}
        >
          {t("insufficient")}
        </div>
      )}
      {noOrder && (
        <div
          id="noOrderNotification"
          className="notification"
          style={{
            fontSize: "1rem",
            display: "flex",
            color: "#fff",
            justifyContent: "center",
            fontWeight: 600,
          }}
        >
          {t("noOrderGenerated")}
        </div>
      )}
      {filledError && (
        <div
          id="taskFilled"
          className="notification"
          style={{
            fontSize: "1rem",
            display: "flex",
            color: "#fff",
            justifyContent: "center",
            fontWeight: 600,
          }}
        >
          {t("completeSetOfTasks")}
        </div>
      )}
      {unfinishedError && (
        <div
          id="normalPending"
          className="notification col-md-3 col-9 text-center"
          style={{
            fontSize: "1rem",
            display: "flex",
            color: "#fff",
            justifyContent: "center",
            fontWeight: 600,
          }}
        >
          {t("pleaseCompleteThePendingOrder")}
        </div>
      )}

      {/* VIP Section */}
      <div className="mx-3 mt-2" style={{ width: "95%" }}>
        <div className="vipContainer" style={{ width: "100%" }}>
          <button
            className="py-3 px-3 my-3 w-100 bg-white"
            style={{ border: "none", borderRadius: "6px" }}
          >
            <div className="float-start">
              <img
                // src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
                src={
                  levelImg == ""
                    ? `${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`
                    : `${adminDomain}storage/app/public/${encodeURI(levelImg)}`
                }
                style={{ width: "30px", height: "30px" }}
                alt=""
              />
              &nbsp;
              <h6
                className="d-inline mt-3 ps-3"
                style={{ color: "black", fontWeight: 700 }}
              >
                {levelName}
              </h6>
            </div>
            <div className="float-end">
              <div
                className="d-inline mt-5 px-2 py-1"
                style={{
                  fontSize: ".7rem",
                  color: "black",
                  fontWeight: 700,
                  backgroundColor: "rgb(241, 187, 14)",
                  borderRadius: "6px",
                }}
              >
                {t("commision")} - {commision}
              </div>
            </div>
          </button>
        </div>
      </div>

      {/* Amazon GIF Section */}
      <div className="mx-2" style={{ width: "95%" }}>
        <div className="w-100">
          <img
            // src="/icons/No need to edit/Amazon gif.gif"
            src={`${process.env.PUBLIC_URL}/images/No need to edit/Amazon gif.gif`}
            className="w-100"
            style={{ borderRadius: "7px" }}
            alt=""
          />
        </div>
      </div>

      {/* Start Button Section */}
      <div className="mx-2" style={{ width: "95%" }}>
        <button
          onClick={() => {
            setIsGenerateOrder(true);
            getOrder();
            // setTimeout(() => {
            //   setIsGenerateOrder(false);
            //   navigate("/order");
            // }, 3000);
          }}
          id="orderBtn"
          className="w-100 my-4 d-flex justify-content-center align-items-center py-md-1 py-1 border-0 text-white"
          style={{ backgroundColor: "black", borderRadius: "5px" }}
        >
          <h5 className="mt-2" style={{ fontSize: ".9rem" }}>
            {t("start")}({currentIndex}, {totalIndex})
          </h5>
        </button>
      </div>

      {/* Withdrawal Modal */}
      {isGenerateOrder && (
        <div
          id="withdrawalModal"
          className="withdrawal-modal"
          style={{ display: "flex" }}
        >
          <div
            className="withdrawal-modal-content withdrawlModal"
            style={{
              opacity: 0.6,
              borderColor: "#333",
              backgroundColor: "#333",
            }}
          >
            <div className="w-100 d-flex justify-content-end"></div>
            <h5
              className="text-center"
              style={{ color: "#fff", fontWeight: 600 }}
            >
              {t("orderGenerating")}
            </h5>
          </div>
        </div>
      )}

      {isModalOpen && (
        <>
          <div
            id="modalOverlay"
            className="modal-overlay"
            style={{
              display: "block",
              position: "fixed",
              zIndex: 1,
              left: 0,
              top: 0,
              width: "100%",
            }}
          ></div>

          <div
            id="myModal"
            className="modal"
            style={{
              display: "flex",
              position: "fixed",
              zIndex: 2,
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="modal-content"
              style={{
                position: "relative",
              }}
            >
              <div
                className="image-container m-0 p-0"
                style={{ position: "relative", width: "100%" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/firstTime.jpg`}
                  alt="Gifting Image"
                  style={{
                    width: "100%",
                    // position: "absolute",
                    height: "100%",
                    display: "block",
                    zIndex: "300",
                  }}
                />
                <div
                  className="text-button-container"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    textAlign: "center",
                    zIndex: 1000,
                    background: "#faf0e6",
                    padding: "15px",
                    boxSizing: "border-box",
                    borderTop:
                      "1px solid #ddd" /* Light border for separation */,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      color: "#333" /* Dark text color for readability */,
                      fontWeight: "bold",
                      fontSize: "18px" /* Slightly larger font for emphasis */,
                    }}
                  >
                    {t("giftText")}${Math.round(amount)}!
                  </p>
                  <button
                    className="action-button"
                    onClick={claimGift}
                    style={{
                      marginTop: "10px",
                      padding: "10px 20px",
                      backgroundColor: "#ffa23c" /* Bright orange color */,
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px" /* Consistent button font size */,
                    }}
                  >
                    {t("claim")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StartingPage;
