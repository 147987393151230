import React, { useEffect, useState } from "react";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import en from "../translations/en.json";
import id from "../translations/id.json";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { updateOrder } from "../features/counterSlice";
const translations = { id, en };
const RecordContainer = ({ data, pending, finished }) => {
  const [language, setLanguage] = useState("id"); // Default language is English

  const t = (key) => translations[language][key] || key;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allState, setAllState] = useState(true);
  const [pendingState, setPendingState] = useState(false);
  const [completedState, SetCompletedState] = useState(false);
  const [allArray, setAllArray] = useState([]);
  const [pendingArray, setPendingArray] = useState([]);
  const [completedArray, setCompletedArray] = useState([]);

  const userData = JSON.parse(getUser());
  const adminDomain = "https://admin.amzscoutmembers.com/";
  const token = getToken();
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };
  const getOrder = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/order",
        {
          id: userData.id,
          permission: "permitted",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        console.log(response.data);
        if (response.data.status == "true") {
          dispatch(updateOrder(response.data));
          navigate("/order");
        }
      }
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  const allClickEvent = () => {
    setPendingState(false);
    SetCompletedState(false);
    setAllState(true);
  };

  const pendingClickEvent = () => {
    SetCompletedState(false);
    setAllState(false);
    setPendingState(true);
  };

  const completeClickEvent = () => {
    setAllState(false);
    setPendingState(false);
    SetCompletedState(true);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/records",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.data);

      setAllArray(response.data.data);
      setPendingArray(response.data.pending);
      setCompletedArray(response.data.finished);
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
  }, []);

  return (
    <div
      style={{ backgroundColor: "#fafafa" }}
      className="position-absolute recordContainer "
    >
      <div
        className="w-100  position-sticky top-0 recoredHeader mt-4"
        style={{ zIndex: 2, top: 0 }}
      >
        <div
          className="text-center py-1"
          onClick={allClickEvent}
          id="all"
          style={{
            cursor: "pointer",
            backgroundColor: allState ? "black" : "white",
            color: allState ? "white" : "black",
            fontWeight: 500,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("all")}
        </div>
        <div
          className="text-center py-1 ms-2"
          id="pending"
          onClick={pendingClickEvent}
          style={{
            cursor: "pointer",
            backgroundColor: pendingState ? "black" : "white",
            color: pendingState ? "white" : "black",
            fontWeight: 500,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("pending")}
        </div>
        <div
          className="text-center py-1 ms-2"
          id="completed"
          onClick={completeClickEvent}
          style={{
            cursor: "pointer",
            backgroundColor: completedState ? "black" : "white",
            color: completedState ? "white" : "black",
            fontWeight: 500,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("completed")}
        </div>
      </div>

      {allState && (
        <div id="allContainer" className=" arrayContainer">
          {allArray.length === 0 && (
            <div
              style={{ color: "rgb(149, 144, 144)" }}
              className="mt-4 text-center successContainer"
            >
              {t("noMoreData")}...
            </div>
          )}

          {allArray.map((obj, index) => (
            <div
              key={obj.id}
              className="mx-md-0 mx-3 my-4 eachObj"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for container
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <div className="my-2">
                <div
                  className="float-start"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                  }}
                  data-timestamp={obj.created_at}
                >
                  {formatTimestamp(obj.created_at)}
                </div>
                {obj.status === "finished" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "rgba(17, 197, 122, 0.936)",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("completed")}
                  </button>
                )}
                {obj.status === "pending" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "black",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("pending")}
                  </button>
                )}
                {obj.status === "attempt" && (
                  <button
                    className="bg-warning float-end"
                    style={{
                      color: "black",
                      border: "none",
                      fontWeight: 480,
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("attempting")}
                  </button>
                )}
              </div>
              <br />
              <div className="bg-white">
                <div className="w-100 mt-2 d-flex">
                  {obj.productCoverPhoto ? (
                    <img
                      src={`${adminDomain}storage/app/public/${encodeURI(
                        obj.productCoverPhoto
                      )}`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/productTwo.jpg`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  )}
                  <div className="recordTextContainer">
                    <div
                      className="px-2"
                      style={{
                        color: "black",
                        fontWeight: 600,
                        maxWidth: "900px",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                      }}
                    >
                      {obj.productName}
                    </div>
                    {/* Rating stars */}
                    {/* ... (same as before) */}
                  </div>
                </div>
                <div className="col-12 mt-md-4 mt-2 recordsPrice row">
                  <div
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div> {t("totalAmount")}</div>
                    <h5 className="recordsPrice">
                      <span style={{ fontWeight: 700 }}>USD</span>{" "}
                      {Number(obj.productQuantity * obj.productPrice).toFixed(
                        2
                      )}
                    </h5>
                  </div>
                  <div
                    className="d-flex justify-content-center col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div>
                      <div> {t("commision")}</div>
                      <h5 className="recordsPrice">
                        <span style={{ fontWeight: 700 }}>USD</span>
                        {Number(
                          obj.commision
                            ? obj.commision
                            : obj.type === "normal"
                            ? +obj.myCommision
                            : obj.myConsecutiveCommision
                        ).toFixed(2)}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    {obj.status === "pending" && (
                      <>
                        <input
                          type="hidden"
                          value="permitted"
                          name="permission"
                        />
                        <button
                          onClick={getOrder}
                          className="float-end mt-0 btn btn-sm btn-warning"
                        >
                          {t("submit")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {pendingState && (
        <div id="pendingContainer" className="arrayContainer">
          {pendingArray.length === 0 && (
            <div
              style={{ color: "rgb(149, 144, 144)" }}
              className="mt-4 text-center successContainer"
            >
              {t("noMoreData")} ...
            </div>
          )}

          {pendingArray.map((obj, index) => (
            <div
              key={obj.id}
              className="mx-md-0 mx-3 my-4 eachObj"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for container
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <div className="my-2">
                <div
                  className="float-start"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                  }}
                  data-timestamp={obj.created_at}
                >
                  {formatTimestamp(obj.created_at)}
                </div>
                {obj.status === "finished" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "rgba(17, 197, 122, 0.936)",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("completed")}
                  </button>
                )}
                {obj.status === "pending" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "black",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("pending")}
                  </button>
                )}
                {obj.status === "attempt" && (
                  <button
                    className="bg-warning float-end"
                    style={{
                      color: "black",
                      border: "none",
                      fontWeight: 480,
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("attempting")}
                  </button>
                )}
              </div>
              <br />
              <div className="bg-white">
                <div className="w-100 mt-2 d-flex">
                  {obj.productCoverPhoto ? (
                    <img
                      src={`${adminDomain}storage/app/public/${encodeURI(
                        obj.productCoverPhoto
                      )}`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/productTwo.jpg`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  )}
                  <div className="recordTextContainer">
                    <div
                      className="px-2"
                      style={{
                        color: "black",
                        fontWeight: 600,
                        maxWidth: "900px",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                      }}
                    >
                      {obj.productName}
                    </div>
                    {/* Rating stars */}
                    {/* ... (same as before) */}
                  </div>
                </div>
                <div className="col-12 mt-md-4 mt-2 recordsPrice row">
                  <div
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div> {t("totalAmount")}</div>
                    <h5 className="recordsPrice">
                      <span style={{ fontWeight: 700 }}>USD</span>{" "}
                      {Number(obj.productQuantity * obj.productPrice).toFixed(
                        2
                      )}
                    </h5>
                  </div>
                  <div
                    className="d-flex justify-content-center col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div>
                      <div> {t("commision")}</div>
                      <h5 className="recordsPrice">
                        <span style={{ fontWeight: 700 }}>USD</span>
                        {Number(
                          obj.commision
                            ? obj.commision
                            : obj.type === "normal"
                            ? +obj.myCommision
                            : obj.myConsecutiveCommision
                        ).toFixed(2)}
                      </h5>
                    </div>
                  </div>
                  <div
                    method="GET"
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    {obj.status === "pending" && (
                      <div>
                        <input
                          type="hidden"
                          value="permitted"
                          name="permission"
                        />
                        <button
                          onClick={getOrder}
                          className="float-end mt-0 btn btn-sm btn-warning"
                        >
                          {t("submit")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {completedState && (
        <div id="pendingContainer" className="arrayContainer">
          {completedArray.length === 0 && (
            <div
              style={{ color: "rgb(149, 144, 144)" }}
              className="mt-4 text-center successContainer"
            >
              {t("noMoreData")} ...
            </div>
          )}

          {completedArray.map((obj, index) => (
            <div
              key={obj.id}
              className="mx-md-0 mx-3 my-4 eachObj"
              style={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Shadow for container
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "#fff",
              }}
            >
              <div className="my-2">
                <div
                  className="float-start"
                  style={{
                    color: "black",
                    fontWeight: 500,
                    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                  }}
                  data-timestamp={obj.created_at}
                >
                  {formatTimestamp(obj.created_at)}
                </div>
                {obj.status === "finished" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "rgba(17, 197, 122, 0.936)",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("completed")}
                  </button>
                )}
                {obj.status === "pending" && (
                  <button
                    className="float-end"
                    style={{
                      color: "white",
                      border: "none",
                      fontWeight: "480",
                      backgroundColor: "black",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("pending")}
                  </button>
                )}
                {obj.status === "attempt" && (
                  <button
                    className="bg-warning float-end"
                    style={{
                      color: "black",
                      border: "none",
                      fontWeight: 480,
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for button
                    }}
                  >
                    {t("attempting")}
                  </button>
                )}
              </div>
              <br />
              <div className="bg-white">
                <div className="w-100 mt-2 d-flex">
                  {obj.productCoverPhoto ? (
                    <img
                      src={`${adminDomain}storage/app/public/${encodeURI(
                        obj.productCoverPhoto
                      )}`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${process.env.PUBLIC_URL}/images/No need to edit/productTwo.jpg`}
                      style={{
                        borderRadius: "6px",
                        width: "90px",
                        height: "90px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Shadow for image
                      }}
                      alt=""
                    />
                  )}

                  <div className="recordTextContainer">
                    <div
                      className="px-2"
                      style={{
                        color: "black",
                        fontWeight: 600,
                        maxWidth: "900px",
                        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                      }}
                    >
                      {obj.productName}
                    </div>
                    {/* Rating stars */}
                    {/* ... (same as before) */}
                  </div>
                </div>
                <div className="col-12 mt-md-4 mt-2 recordsPrice row">
                  <div
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div> {t("totalAmount")}</div>
                    <h5 className="recordsPrice">
                      <span style={{ fontWeight: 700 }}>USD</span>{" "}
                      {Number(obj.productQuantity * obj.productPrice).toFixed(
                        2
                      )}
                    </h5>
                  </div>
                  <div
                    className="d-flex justify-content-center col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    <div>
                      <div> {t("commision")}</div>
                      <h5 className="recordsPrice">
                        <span style={{ fontWeight: 700 }}>USD</span>
                        {Number(
                          obj.commision
                            ? obj.commision
                            : obj.type === "normal"
                            ? +obj.myCommision
                            : obj.myConsecutiveCommision
                        ).toFixed(2)}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      color: "black",
                      fontWeight: 500,
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)", // Shadow for text
                    }}
                  >
                    {obj.status === "pending" && (
                      <>
                        <input
                          type="hidden"
                          value="permitted"
                          name="permission"
                        />
                        <button
                          onClick={getOrder}
                          className="float-end mt-0 btn btn-sm btn-warning"
                        >
                          {t("submit")}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RecordContainer;
