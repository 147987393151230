import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import id from "../translations/id.json";
import en from "../translations/en.json";
const translations = { id, en };
const WHistoryPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("reviewing");
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };
  const myUserData = JSON.parse(getUser());
  const token = getToken();

  const [pendingData, setPendingData] = useState([]);
  const [data, setData] = useState([]);
  const [rejectData, setRejectData] = useState([]);

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/whistory",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setData(response.data.data);
        setPendingData(response.data.pendingData);
        setRejectData(response.data.rejectData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
    const convertToLocalTime = (utcTime, timeZone) => {
      return new Date(utcTime).toLocaleString("en-US", {
        timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timestamps = document.querySelectorAll(".timestamp");

    timestamps.forEach((timestamp) => {
      const utcTime = timestamp.getAttribute("data-timestamp");
      const localTime = convertToLocalTime(utcTime, userTimeZone);
      timestamp.textContent = localTime;
    });
  }, []);

  const clearFunction = () => {
    document.getElementById("reviewing").style.backgroundColor = "white";
    document.getElementById("reviewing").style.fontWeight = "500";
    document.getElementById("success").style.backgroundColor = "white";
    document.getElementById("success").style.fontWeight = "500";
    document.getElementById("reject").style.backgroundColor = "white";
    document.getElementById("reject").style.fontWeight = "500";
    document.getElementById("reviewingContainer").style.display = "none";
    document.getElementById("rejectContainer").style.display = "none";
    document.getElementById("successContainer").style.display = "none";
  };

  const handleTabClick = (tab) => {
    clearFunction();
    document.getElementById(tab).style.backgroundColor = "rgb(241, 187, 14)";
    document.getElementById(tab).style.fontWeight = "600";
    document.getElementById(`${tab}Container`).style.display = "block";
    setCurrentTab(tab);
  };

  return (
    <div className="loginBody">
      <div
        className="loginContainer"
        style={{ overflow: "initial", backgroundColor: "#f9f9f9" }}
      >
        <div
          className="d-flex py-2 sticky-header position-fixed top-0"
          style={{ top: "0", backgroundColor: "black" }}
        >
          <div id="backBtn" style={{ color: "white", cursor: "pointer" }}>
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate(-1);
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "25px", height: "25px" }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="m-0 p-0 text-white font-weight-bold text-center col-11 mt-1"
            style={{ fontSize: ".9rem" }}
          >
            {t("transactionHistory")}
          </h5>
        </div>

        <br />
        <br />

        <div className="mt-3 px-3 py-3">
          <div className="col-12 d-flex row">
            <div
              id="reviewing"
              className="text-center py-2"
              style={{
                fontWeight: "600",
                width: "32%",
                backgroundColor: "rgb(241, 187, 14)",
                cursor: "pointer",
                borderRadius: "4px",
                color: "black",
              }}
              onClick={() => handleTabClick("reviewing")}
            >
              {t("reviewing")}
            </div>
            <div
              id="success"
              className="ms-1 text-center py-2"
              style={{
                width: "32%",
                color: "black",
                cursor: "pointer",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              onClick={() => handleTabClick("success")}
            >
              {t("success")}
            </div>
            <div
              id="reject"
              className="ms-1 text-center py-2"
              style={{
                width: "32%",
                color: "black",
                cursor: "pointer",
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              onClick={() => handleTabClick("reject")}
            >
              {t("success")}
            </div>
          </div>

          <div
            id="reviewingContainer"
            style={{ display: currentTab === "reviewing" ? "block" : "none" }}
          >
            {pendingData.length === 0 ? (
              <div
                id="noData"
                style={{ color: "rgb(149, 144, 144)" }}
                className="mt-4 text-center"
              >
                {t("noMoreData")} ...
              </div>
            ) : (
              pendingData.map((each) => (
                <div
                  key={each.id}
                  className="ms-1 py-2 rounded-1 bg-white text-decoration-none col-12 row mt-2 eachNoti my-2 px-0"
                  style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="d-flex flex-column justify-content-center overflow-hidden col-md-1 col-2 px-0">
                    <div className="d-flex flex-column justify-content-center col-12 col-md-8 mx-0 px-0">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                        style={{ width: "100%", height: "50px" }}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="px-0 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                    <div>
                      <div
                        className="eachMessageText text-decoration-none"
                        style={{ fontWeight: "660", color: "black" }}
                      >
                        {t("withdrawalAmount")} - {each.amount}
                      </div>
                      <small
                        className="text-decoration-none timestamp"
                        data-timestamp={each.created_at}
                        style={{ color: "black" }}
                      >
                        {formatTimestamp(each.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          <div
            id="rejectContainer"
            style={{ display: currentTab === "reject" ? "block" : "none" }}
          >
            {rejectData.length === 0 ? (
              <div
                id="noData"
                style={{ color: "rgb(149, 144, 144)" }}
                className="mt-4 text-center"
              >
                {t("noMoreData")} ...
              </div>
            ) : (
              rejectData.map((each) => (
                <div
                  key={each.id}
                  className="ms-1 py-2 rounded-1 bg-white text-decoration-none col-12 row mt-2 eachNoti my-2 px-0"
                  style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="d-flex flex-column justify-content-center overflow-hidden col-md-1 col-2 px-0">
                    <div className="d-flex flex-column justify-content-center col-12 col-md-8 mx-0 px-0">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                        style={{ width: "100%", height: "50px" }}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="px-0 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                    <div>
                      <div
                        className="eachMessageText text-decoration-none"
                        style={{ fontWeight: "660", color: "black" }}
                      >
                        Withdrawal Amount -{each.amount}
                      </div>
                      <small
                        className="text-decoration-none timestamp"
                        data-timestamp={each.created_at}
                        style={{ color: "black" }}
                      >
                        {formatTimestamp(each.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          <div
            id="successContainer"
            style={{ display: currentTab === "success" ? "block" : "none" }}
          >
            {data.length === 0 ? (
              <div
                id="noData"
                style={{ color: "rgb(149, 144, 144)" }}
                className="mt-4 text-center"
              >
                {t("noMoreData")} ...
              </div>
            ) : (
              data.map((each) => (
                <div
                  key={each.id}
                  className="ms-1 py-2 rounded-1 bg-white text-decoration-none col-12 row mt-2 eachNoti my-2 px-0"
                  style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="d-flex flex-column justify-content-center overflow-hidden col-md-1 col-2 px-0">
                    <div className="d-flex flex-column justify-content-center col-12 col-md-8 mx-0 px-0">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                        style={{ width: "100%", height: "50px" }}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="px-0 offset-md-0 offset-1 col-9 messagesText pt-3 d-flex flex-column justify-content-center">
                    <div>
                      <div
                        className="eachMessageText text-decoration-none"
                        style={{ fontWeight: "660", color: "black" }}
                      >
                        Withdrawal Amount - {each.amount}
                      </div>
                      <small
                        className="text-decoration-none timestamp"
                        data-timestamp={each.created_at}
                        style={{ color: "black" }}
                      >
                        {formatTimestamp(each.created_at)}
                      </small>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="" style={{ height: "60vh" }}></div>
      </div>
    </div>
  );
};

export default WHistoryPage;
