import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import en from "../translations/en.json";
import id from "../translations/id.json";

import axios from "axios";
const translations = { en, id };
const DepositPage = () => {
  const [language, setLanguage] = useState("id");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const myUserData = JSON.parse(getUser());
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({
    todayPrice: 0,
    todayTeam: 0,
    totalPrice: 0,
    totalTeam: 0,
  });
  const token = getToken();
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/deposit",
        {
          id: myUserData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      if (response.data.status == "true") {
        setData(response.data.data);
        setUserData(response.data.userData);
      }
    } catch (error) {
      console.log(error);
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  // const data = [
  //   { amount: 500, created_at: "2023-06-05T11:55:06" },
  //   { amount: 300, created_at: "2023-07-05T14:45:26" },
  // ];
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // For 12-hour format with AM/PM
    };
    return date.toLocaleString("en-US", options);
  };
  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();
    const convertToLocalTime = (utcTime, timeZone) => {
      const localTime = new Date(utcTime).toLocaleString("en-US", {
        timeZone: timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return localTime;
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timestamps = document.querySelectorAll(".timestamp");
    timestamps.forEach((timestamp) => {
      const utcTime = timestamp.getAttribute("data-timestamp");
      const localTime = convertToLocalTime(utcTime, userTimeZone);
      timestamp.textContent = localTime;
    });

    const generateRandomNumber = () => {
      var randomNumber = Math.floor(10000000 + Math.random() * 90000000);
      return randomNumber.toString().substring(0, 8); // Ensure exactly 8 digits
    };

    var elements = document.getElementsByClassName("random-id");
    for (var i = 0; i < elements.length; i++) {
      elements[i].innerHTML = generateRandomNumber();
    }

    const modal = document.getElementById("logoutModal");
    const openModalBtn = document.getElementById("depositBtn");
    const closeModalBtn = document.getElementById("logoutCloseBtn");
    const confirmBtn = document.getElementById("logoutConfirm");

    openModalBtn.addEventListener("click", function () {
      modal.style.display = "block";
    });

    closeModalBtn.addEventListener("click", function () {
      modal.style.display = "none";
    });

    window.addEventListener("click", function (event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    });

    confirmBtn.addEventListener("click", function () {
      modal.style.display = "none";
      window.location.href = "https://direct.lc.chat/18247440/";
    });
  }, []);

  return (
    <div className="loginBody">
      <div
        className="loginContainer"
        style={{ overflow: "initial !important" }}
      >
        <div
          className="d-flex py-2 sticky-header position-fixed top-0"
          style={{ top: "0", backgroundColor: "black" }}
        >
          <div id="backBtn" style={{ color: "white", cursor: "pointer" }}>
            <div className="ms-3">
              <img
                onClick={() => {
                  navigate("/");
                }}
                src={`${process.env.PUBLIC_URL}/images/No need to edit/back.jpg`}
                style={{ width: "25px", height: "25px" }}
                alt=""
              />
            </div>
          </div>
          <h5
            className="m-0 p-0 text-white font-weight-bold text-center col-11 mt-1"
            style={{ fontSize: ".9rem" }}
          >
            {t("deposit")}
          </h5>
        </div>

        <br />
        <br />

        <div className="mt-1 mx-2 px-3 py-1" style={{ width: "97%" }}>
          <div className="mt-3 mx-md-3 mx-1 homeBigCardContainer">
            <div
              className="py-3 px-md-3 px-2 homeBigCard"
              style={{
                width: "100%",
                backgroundColor: "black",
                borderRadius: "10px",
              }}
            >
              <h6 style={{ fontWeight: 600 }} className="my-0 py-0 text-white">
                {t("walletAccount")}
              </h6>
              <div className="d-flex text-white">
                <h3
                  style={{ fontStyle: "italic", fontWeight: 700 }}
                  className="mt-2"
                >
                  {userData.wallet_balance}
                </h3>
                <div className="mt-3 pt-1" style={{ fontSize: ".8rem" }}>
                  USD
                </div>
              </div>

              <div className="mt-2 homeBtnContainer">
                <button
                  onClick={() => {
                    todayClicked();
                  }}
                  id="firstToday"
                  className="px-1"
                  style={{
                    backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                    color: isToday ? "black" : "white",
                    borderRadius: "3px",
                    paddingTop: "2px",
                    border: isToday ? "none" : "white solid .4px",
                    paddingBottom: "2px",

                    fontSize: ".8rem",
                    outline: "none",
                  }}
                >
                  {t("todayEarnings")}
                </button>
                <button
                  id="firstTotal"
                  onClick={() => {
                    totalClicked();
                  }}
                  className="ms-3 px-1  "
                  style={{
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: ".8rem",
                    outline: "none",
                    border: isTotal ? "none" : "white solid .4px",
                    backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                    color: isTotal ? "black" : "white",
                    borderRadius: "3px",
                  }}
                >
                  {t("totalEarnings")}
                </button>
                {/* <button
              id="secondTotal"
              className="px-1  text-white"
              style={{

                paddingTop: "2px",
                paddingBottom: "2px",
                fontSize: ".8rem",
                outline: "none",
                backgroundColor: "black",
                borderRadius: "3px",
                display: "none",
              }}
            >
              Todays Earnings
            </button>
            <button
              id="secondToday"
              className="ms-3 px-1"
              style={{
                display: "none",
                backgroundColor: "rgb(241, 187, 14)",
                borderRadius: "3px",
                paddingTop: "2px",
                paddingBottom: "2px",
                border: 0,
                fontSize: ".8rem",
                outline: "none",
              }}
            >
              Total Earnings
            </button> */}
              </div>

              <div className="homeCasesContainer d-flex mt-md-2 mt-2">
                <div
                  id="firstOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("orderRevenue")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayPrice.toFixed(3)
                      : userData.totalPrice.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "inline",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("team")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? userData.todayTeam.toFixed(3)
                      : userData.totalTeam.toFixed(3)}
                  </div>
                </div>
                <div
                  id="firstEarning"
                  style={{ display: "inline" }}
                  className="totalContainer me-3 pe-2"
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("totalEarnings")}
                  </div>
                  <div className="text-white">
                    {isToday
                      ? (userData.todayPrice + userData.totalPrice).toFixed(3)
                      : (userData.todayTeam + userData.totalTeam).toFixed(3)}
                  </div>
                </div>
                <div
                  id="secondOrder"
                  className="revenueContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("orderRevenue")}
                  </div>
                  <div className="text-white">345.2</div>
                </div>
                <div
                  id="secondTeam"
                  className="teamContainer me-3 pe-2"
                  style={{
                    display: "none",
                    borderRight: "solid 1px rgb(241, 187, 14)",
                  }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("team")}
                  </div>
                  <div className="text-white">345.23</div>
                </div>
                <div
                  id="secondEarning"
                  className="totalContainer me-3 pe-2"
                  style={{ display: "none" }}
                >
                  <div
                    style={{
                      color: "rgb(197, 196, 196)",
                      fontSize: ".8rem",
                      fontWeight: 460,
                    }}
                  >
                    {t("totalEarnings")}
                  </div>
                  <div className="text-white">342.22</div>
                </div>
              </div>
            </div>
          </div>

          <button
            className="mt-4 w-100 mb-1 py-2 border-0 text-white"
            style={{ backgroundColor: "black", borderRadius: "5px" }}
          >
            <h5 style={{ fontSize: ".9rem" }} id="depositBtn" className="mt-1">
              Crypto Deposit
            </h5>
          </button>
          <div className="d-flex justify-content-center">
            <small
              className="fp-link1 text-center"
              style={{ fontWeight: 450, fontSize: ".8rem" }}
            >
              **{t("depositsProceedTime")}**
            </small>
          </div>

          <div class=" mt-2 mb-2 bg-white">
            <div class=" float-start">
              <div
                class=" d-inline ml-1"
                style={{ fontWeight: "690", color: "black" }}
              >
                {t("depositHistory")}
              </div>
            </div>
            <div
              onClick={() => {
                navigate("/dhistory");
              }}
              class=" float-right "
              style={{ color: "rgb(243, 187, 6)", cursor: "pointer" }}
            >
              <div class=" d-inline ml-3" style={{ fontWeight: "690" }}>
                {t("history")}
              </div>
              <FontAwesomeIcon className=" ms-2" icon={faArrowRight} />
            </div>
          </div>

          <br className=" mt-3" />

          <div className="mt-1 mb-5 w-100">
            {data.map((item, index) => (
              <div
                key={index}
                className="py-3 px-3"
                style={{
                  width: "100%",
                  borderBottom: "solid 1px black",
                  backgroundColor: "white",
                  borderRadius: "3px",
                }}
              >
                <div
                  className="d-flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <h6 className="my-0 py-0 text-dark">Crypto Deposit</h6>
                  <h6 className="my-0 py-0 text-dark">{item.amount} USD</h6>
                </div>
                <div className="text-muted" style={{ fontSize: ".8rem" }}>
                  <small className="timestamp" data-timestamp={item.created_at}>
                    {formatTimestamp(item.created_at)}
                  </small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div id="logoutModal" className="transaction-modal">
        <div className="transaction-modal-content px-3">
          <h5
            className="text-center"
            style={{
              color: "rgb(120, 115, 115)",
              fontWeight: 600,
              fontSize: "0.9rem",
            }}
          >
            {t("contactCustomerServiceCenter")}
          </h5>
          <div className="d-flex justify-content-center">
            <div className="d-flex col-md-10 col-12">
              <button
                id="logoutCloseBtn"
                className="my-3 col-6 py-2 w-50 border-0"
                style={{
                  backgroundColor: "rgb(180, 176, 176)",
                  borderTopLeftRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              >
                <h6 className="text-black">{t("cancel")}</h6>
              </button>
              <button
                id="logoutConfirm"
                className="my-3 col-6 py-2 w-50 border-0"
                style={{
                  backgroundColor: "black",
                  borderTopRightRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                <h6 className="text-white">{t("confirm")}</h6>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="" style={{ height: "60vh" }}></div>
    </div>
  );
};

export default DepositPage;
