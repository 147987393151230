import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import bcrypt from "bcryptjs";
import {
  getToken,
  getUser,
  removeToken,
  removeUser,
} from "../utils/localStorage";
import axios from "axios";
import { faL } from "@fortawesome/free-solid-svg-icons";
// import "./ProfilePage.css"; // Import external CSS
import en from "../translations/en.json";
import id from "../translations/id.json";
const translations = { id, en };
const SettingsPage = () => {
  const [language, setLanguage] = useState("en");

  const t = (key) => translations[language][key] || key;
  const navigate = useNavigate();
  const [levelImg, setLevelImg] = useState("");
  const [userData, setUserData] = useState(JSON.parse(getUser()));
  const token = getToken();
  const [inviteCode, setInviteCode] = useState("");
  const [wallet, setWallet] = useState(0);
  const [todayPrice, setTodayPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [todayTeam, setTodayTeam] = useState(0);
  const [totalTeam, setTotalTeam] = useState(0);
  const [levelName, setLevelName] = useState("VIP 1");
  const adminDomain = "https://admin.amzscoutmembers.com/";
  const domain = "https://amzscoutmembers.com/";
  const [isToday, setIsToday] = useState(true);
  const [isTotal, setIsTotal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isTrading, setIsTrading] = useState(false);
  const [vipName, setVipName] = useState("");
  const [tranPassword, setTranPassword] = useState("");
  const [hashedPassword, setHashedPassword] = useState("");
  const [tradingPassword, setTradingPassword] = useState("");

  const [falseTranPass, setFalseTranPass] = useState(false);
  const [errorTradingPassword, setErrorTradingPassword] = useState(false);
  const [errorTransactionPassword, setErrorTransactionPassword] =
    useState(false);
  function checkPassword(plainPassword, hashedPassword) {
    return bcrypt.compareSync(plainPassword, hashedPassword);
  }
  const logoutClick = () => {
    setIsLogout(true);
  };
  const withdrawClick = () => {
    setIsWithdraw(true);
  };
  const toWithdraw = () => {
    if (tranPassword == "") {
      setFalseTranPass(true);
      setTimeout(() => {
        setFalseTranPass(false);
      }, 2000);
      setTranPassword("");
      return;
    }

    if (checkPassword(tranPassword, hashedPassword)) {
      setTranPassword("");
      navigate("/withdraw");
    } else {
      setIsWithdraw(false);
      setErrorTransactionPassword(true);
      setTimeout(() => {
        setErrorTransactionPassword(false);
      }, 2000);
      setTranPassword("");
    }
  };
  const tradingClick = () => {
    setIsTrading(true);
  };

  const toTrading = () => {
    if (tradingPassword == "") {
      setErrorTradingPassword(true);
      setTradingPassword("");
      setTimeout(() => {
        setErrorTradingPassword(false);
        setTradingPassword("");
      }, 2000);
      return;
    }

    if (checkPassword(tradingPassword, hashedPassword)) {
      navigate("/withdraw");
      setTradingPassword("");
    } else {
      setIsTrading(false);
      setErrorTransactionPassword(true);
      setTimeout(() => {
        setErrorTransactionPassword(false);
      }, 2000);
      setTradingPassword("");
    }
  };

  const orderClickBtn = () => {
    const textToCopy = userData.invite_code;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        setIsCopied(false);
        console.error("Could not copy text: ", err);
      });
  };
  const todayClicked = () => {
    setIsTotal(false);
    setIsToday(true);
  };
  const totalClicked = () => {
    setIsToday(false);
    setIsTotal(true);
  };

  const confirmLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
    setIsLogout(false);
  };

  const fetchData = async () => {
    if (!token) {
      navigate("/login");
    }

    try {
      const response = await axios.post(
        "https://admin.amzscoutmembers.com/api/home",
        {
          id: userData.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setLevelName(response.data.data.memberLevelName);
      setLevelImg(response.data.data.levelImg);
      setUserData(response.data.data);
      setInviteCode(response.data.data.invite_code);
      setWallet(response.data.data.wallet_balance);
      setTodayPrice(response.data.data.todayPrice);
      setTodayTeam(response.data.data.todayTeam);
      setTotalPrice(response.data.data.totalPrice);
      setTotalTeam(response.data.data.totalTeam);
      setHashedPassword(response.data.data.transactionPassword);

      setVipName(response.data.data.memberLevelName);
    } catch (error) {
      if (error.message == "Request failed with status code 401") {
        removeToken();
        removeUser();
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("languageCode")) {
      setLanguage(localStorage.getItem("languageCode"));
    }
    fetchData();

    // Clear interval on component unmount
  }, []);

  return (
    <div className="ps-3 mt-3">
      <style>
        {`
          .notification {
    opacity:0.6;
  display: flex;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #333;
        color: #fff;
        padding: 15px;
        border-radius: 5px;
        z-index: 1000;
    }
          `}
      </style>
      <div className="profilesHeader">
        <div className="ms-2 d-flex">
          <img
            src={`${process.env.PUBLIC_URL}/images/No need to edit/Profile logo.png`}
            style={{ width: "65px", height: "65px" }}
            alt="Profile Logo"
          />
          <div>
            <div className="d-block ms-2 profileTextContainer">
              <div className="m-0 p-0 d-flex">
                <h5
                  className="m-0 p-0"
                  style={{ color: "black", fontWeight: 650 }}
                >
                  {userData.name}
                </h5>
                <img
                  src={
                    levelImg == ""
                      ? `${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`
                      : `${adminDomain}storage/app/public/${encodeURI(
                          levelImg
                        )}`
                  }
                  style={{ width: "24px", height: "24px" }}
                  className="ms-2"
                  alt="Level"
                />
              </div>
            </div>
            <div className="w-100 ms-2">
              <h6 className="w-100 d-flex text-secondary">
                {t("creditScore")}:
                <div
                  className="mt-2 ms-2"
                  style={{
                    backgroundColor: "rgb(241, 187, 14)",
                    height: "1px",
                    width: `${userData.reputation_score}px`,
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                ></div>
                <div style={{ color: "black", fontWeight: 700 }}>
                  {userData.reputation_score}%
                </div>
              </h6>
            </div>
            <div className="w-100 ms-2">
              <h6
                className="w-100 m-0 p-0 d-flex text-secondary"
                style={{ fontWeight: 560 }}
              >
                {t("invitationCode")}:
                <div
                  style={{ fontWeight: 700, color: "black" }}
                  className="ms-2"
                >
                  {userData.invite_code}
                </div>
                <div
                  onClick={() => {
                    orderClickBtn();
                  }}
                  id="copyBtn"
                  className=" ms-2 px-1 py-1"
                  style={{
                    cursor: "pointer",
                    fontSize: ".8rem",
                    fontWeight: 600,
                    color: "black",
                    backgroundColor: "rgb(241, 187, 14)",
                    borderRadius: "9px",
                  }}
                >
                  {t("copy")}
                </div>
              </h6>
            </div>
          </div>
        </div>
      </div>

      {/* Big Card */}
      <div className="mx-2" style={{ width: "95%" }}>
        <div className="mt-3 mx-md-3 mx-1 homeBigCardContainer">
          <div
            className="py-3 px-md-3 px-2 homeBigCard"
            style={{
              width: "100%",
              backgroundColor: "black",
              borderRadius: "10px",
            }}
          >
            <h6 style={{ fontWeight: 600 }} className="my-0 py-0 text-white">
              {t("walletAccount")}
            </h6>
            <div className="d-flex text-white">
              <h3
                style={{ fontStyle: "italic", fontWeight: 700 }}
                className="mt-2"
              >
                {wallet}
              </h3>
              <div className="mt-3 pt-1" style={{ fontSize: ".8rem" }}>
                USD
              </div>
            </div>

            <div className="mt-2 homeBtnContainer">
              <button
                onClick={() => {
                  todayClicked();
                }}
                id="firstToday"
                className="px-1"
                style={{
                  backgroundColor: isToday ? "rgb(241, 187, 14)" : "black",
                  color: isToday ? "black" : "white",
                  borderRadius: "3px",
                  paddingTop: "2px",
                  border: isToday ? "none" : "white solid .4px",
                  paddingBottom: "2px",

                  fontSize: ".8rem",
                  outline: "none",
                }}
              >
                {t("todayEarnings")}
              </button>
              <button
                id="firstTotal"
                onClick={() => {
                  totalClicked();
                }}
                className="ms-3 px-1  "
                style={{
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  fontSize: ".8rem",
                  outline: "none",
                  border: isTotal ? "none" : "white solid .4px",
                  backgroundColor: isTotal ? "rgb(241, 187, 14)" : "black",
                  color: isTotal ? "black" : "white",
                  borderRadius: "3px",
                }}
              >
                {t("totalEarnings")}
              </button>
              {/* <button
              id="secondTotal"
              className="px-1  text-white"
              style={{

                paddingTop: "2px",
                paddingBottom: "2px",
                fontSize: ".8rem",
                outline: "none",
                backgroundColor: "black",
                borderRadius: "3px",
                display: "none",
              }}
            >
              Todays Earnings
            </button>
            <button
              id="secondToday"
              className="ms-3 px-1"
              style={{
                display: "none",
                backgroundColor: "rgb(241, 187, 14)",
                borderRadius: "3px",
                paddingTop: "2px",
                paddingBottom: "2px",
                border: 0,
                fontSize: ".8rem",
                outline: "none",
              }}
            >
              Total Earnings
            </button> */}
            </div>

            <div className="homeCasesContainer d-flex mt-md-2 mt-2">
              <div
                id="firstOrder"
                className="revenueContainer me-3 pe-2"
                style={{
                  display: "inline",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("orderRevenue")}
                </div>
                <div className="text-white">
                  {isToday ? todayPrice.toFixed(3) : totalPrice.toFixed(3)}
                </div>
              </div>
              <div
                id="firstTeam"
                className="teamContainer me-3 pe-2"
                style={{
                  display: "inline",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("team")}
                </div>
                <div className="text-white">
                  {isToday ? todayTeam.toFixed(3) : totalTeam.toFixed(3)}
                </div>
              </div>
              <div
                id="firstEarning"
                style={{ display: "inline" }}
                className="totalContainer me-3 pe-2"
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("totalEarnings")}
                </div>
                <div className="text-white">
                  {isToday
                    ? (todayPrice + todayTeam).toFixed(3)
                    : (totalPrice + totalTeam).toFixed(3)}
                </div>
              </div>
              <div
                id="secondOrder"
                className="revenueContainer me-3 pe-2"
                style={{
                  display: "none",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("orderRevenue")}
                </div>
                <div className="text-white">345.2</div>
              </div>
              <div
                id="secondTeam"
                className="teamContainer me-3 pe-2"
                style={{
                  display: "none",
                  borderRight: "solid 1px rgb(241, 187, 14)",
                }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("team")}
                </div>
                <div className="text-white">345.23</div>
              </div>
              <div
                id="secondEarning"
                className="totalContainer me-3 pe-2"
                style={{ display: "none" }}
              >
                <div
                  style={{
                    color: "rgb(197, 196, 196)",
                    fontSize: ".8rem",
                    fontWeight: 460,
                  }}
                >
                  {t("totalEarnings")}
                </div>
                <div className="text-white">342.22</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Icon Container */}
      <div className="mx-2" style={{ width: "95%" }}>
        <div className="mt-md-4 mt-2">
          <h6 style={{ color: "black", fontWeight: 650 }}>
            {t("transactionHistory")}
          </h6>
        </div>
        <div className="mt-2 col-12 px-0 mx-0 d-flex justify-content-between">
          <div
            onClick={() => {
              navigate("/deposit");
            }}
            style={{ cursor: "pointer" }}
            className="text-decoration-none eachIcon"
          >
            <div className="d-flex justify-content-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Deposit.png`}
                alt="Deposit"
                className="depositLogo"
              />
            </div>
            <div
              className="text-center homeLogosText"
              style={{ color: "black", fontWeight: 650, fontSize: ".9rem" }}
            >
              {t("deposit")}
            </div>
          </div>
          <div
            onClick={() => {
              withdrawClick();
            }}
            style={{ cursor: "pointer" }}
            id="withdrawalOpenModalBtn"
            className="eachIcon"
          >
            <div className="d-flex justify-content-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Withdrawal.png`}
                alt="Withdraw"
                className="profileLogos"
              />
            </div>
            <div
              className="text-center homeLogosText"
              style={{ color: "black", fontWeight: 650, fontSize: ".9rem" }}
            >
              {t("withdraw")}
            </div>
          </div>
          <div
            id="revenueOpenModalBtn"
            onClick={() => {
              tradingClick();
            }}
            style={{ cursor: "pointer" }}
            className=" text-decoration-none eachIcon"
          >
            <div className="d-flex justify-content-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Trading account.png`}
                alt="Trading Account"
                className="profileLogos"
              />
            </div>
            <div
              className="text-center homeLogosText"
              style={{ color: "black", fontWeight: 650, fontSize: ".9rem" }}
            >
              {t("tradingAccount")}
            </div>
          </div>
          <div
            id="personalModal"
            onClick={() => {
              navigate("/information");
            }}
            style={{ cursor: "pointer" }}
            className="eachIcon"
          >
            <div className="d-flex  justify-content-center">
              <img
                src={`${process.env.PUBLIC_URL}/images/Change them into Red/Personal Info.png`}
                alt="Personal Info"
                className="profileLogos"
              />
            </div>
            <div
              className="text-center homeLogosText"
              style={{ color: "black", fontWeight: 650, fontSize: ".9rem" }}
            >
              {t("personalInformation")}
            </div>
          </div>
        </div>

        <div className=" mx-2" style={{ width: "97%" }}>
          <div className=" mt-md-4 mt-2">
            <h6 style={{ color: "black", fontWeight: "650" }}>
              {t("vipLevels")}
            </h6>

            <div className=" vipContainer">
              <button
                onClick={() => {
                  navigate("/vipLevel");
                }}
                className=" py-3 px-3 my-3 w-100"
                style={{
                  backgroundColor: "rgb(241, 187, 14)",
                  border: "none",
                  borderRadius: "6px",
                }}
              >
                <div className=" float-start">
                  <img
                    src={
                      levelImg == ""
                        ? `${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`
                        : `${adminDomain}storage/app/public/${encodeURI(
                            levelImg
                          )}`
                    }
                    // src={`${process.env.PUBLIC_URL}/images/No need to edit/vip1.png`}
                    style={{ width: "40px", height: "40px" }}
                    alt=""
                  />
                  &nbsp;
                  <h6
                    className="  d-inline mt-3 ps-3"
                    style={{ color: "black", fontWeight: "700" }}
                  >
                    {levelName}
                  </h6>
                </div>

                <div className=" float-end">
                  <div className=" mt-2">
                    <h6
                      className="   d-inline mt-3"
                      style={{
                        marginTop: "10px",
                        color: "black",
                        fontWeight: "700",
                      }}
                    >
                      {t("currentVipLevel")}
                    </h6>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        {/* {{-- end --}} */}

        <div className="bg-white py-3 px-2">
          <div
            className="mt-4 text-decoration-none"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/myNotice");
            }}
          >
            <div className="py-2">
              <div className="d-flex bg-white justify-content-center align-items-center float-start">
                <div>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/noti.png`}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "6px",
                    }}
                    alt=""
                  />
                  <div
                    className="d-inline ms-3 text-black"
                    style={{ fontWeight: "650" }}
                  >
                    {t("officialAnnouncement")}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center text-decoration-none float-end">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                  style={{
                    color: "rgb(116, 111, 111)",
                    width: "20px",
                    height: "20px",
                  }}
                  className="ms-2"
                  alt=""
                />
              </div>
            </div>
          </div>

          <br />

          <hr className="mt-3" />

          <div
            onClick={() => {
              navigate("/moreServices");
            }}
            id="moreServices"
            style={{ cursor: "pointer" }}
            className="mt-2 mb-md-3 mb-5 w-100 bg-white"
          >
            <div className="d-flex justify-content-center align-items-center float-start">
              <div className="mt-3">
                <img
                  src={`${process.env.PUBLIC_URL}/images/No need to edit/book.png`}
                  style={{ width: "30px", height: "30px", borderRadius: "6px" }}
                  alt=""
                />
                <div className="d-inline ms-3" style={{ fontWeight: "650" }}>
                  {t("moreServices")}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center float-end">
              <img
                src={`${process.env.PUBLIC_URL}/images/No need to edit/forward.png`}
                style={{ width: "20px", height: "20px" }}
                className="ms-2 mt-3"
                alt=""
              />
            </div>
            <br className="d-md-block d-none" />
            <br className="d-md-block d-none" />
            <br />
          </div>
        </div>

        {isCopied && (
          <div id="copyNotification" className="notification">
            {t("copiedInviteCode")}
          </div>
        )}

        {isTrading && (
          <div id="transactionModal" className="transaction-modal d-flex">
            <div className="transaction-modal-content px-3">
              <div className="w-100 d-flex justify-content-end">
                <h2
                  id="transactionCloseBtn"
                  className="p-0 mr-2 font-weight-normal"
                >
                  <img
                    onClick={() => {
                      setIsTrading(false);
                    }}
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/OIP.jpg`}
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                  />
                </h2>
              </div>
              <h5
                className="text-center"
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {t("enterTransactionPassword")}
              </h5>
              <div className="d-flex justify-content-center w-100">
                <input
                  id="transactionPassword"
                  type="password"
                  className="p-2 text-center"
                  style={{ width: "80%" }}
                  value={tradingPassword}
                  onChange={(e) => {
                    setTradingPassword(e.target.value);
                  }}
                  placeholder={t("enterTransactionPassword")}
                />
              </div>
              {errorTradingPassword && (
                <small className=" text-danger ml-5" id="errorMyTransaction">
                  {t("pleaseEnterTransactionPassword")}
                </small>
              )}
              <div className="d-flex justify-content-center">
                <button
                  id="transactionAcceptBtn"
                  onClick={() => {
                    toTrading();
                  }}
                  className="my-3 py-2 w-50 border-0"
                  style={{ backgroundColor: "black", borderRadius: "3px" }}
                >
                  <h6 className="text-white">{t("confirm")}</h6>
                </button>
              </div>
            </div>
          </div>
        )}

        {isWithdraw && (
          <div id="transactionModal" className="transaction-modal d-flex">
            <div className="transaction-modal-content px-3">
              <div className="w-100 d-flex justify-content-end">
                <h2
                  id="transactionCloseBtn"
                  className="p-0 mr-2 font-weight-normal"
                >
                  <img
                    onClick={() => {
                      setIsWithdraw(false);
                    }}
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/OIP.jpg`}
                    style={{ width: "30px", height: "30px" }}
                    alt=""
                  />
                </h2>
              </div>
              <h5
                className="text-center"
                style={{
                  color: "black",
                  fontWeight: "600",
                }}
              >
                {t("enterTransactionPassword")}
              </h5>
              <div className="d-flex justify-content-center w-100">
                <input
                  id="transactionPassword"
                  type="password"
                  className="p-2 text-center"
                  style={{ width: "80%" }}
                  onChange={(e) => {
                    setTranPassword(e.target.value);
                  }}
                  value={tranPassword}
                  placeholder="Enter Transaction Password"
                />
              </div>
              {falseTranPass && (
                <small className=" text-danger ml-5" id="errorMyTransaction">
                  {t("pleaseEnterTransactionPassword")}
                </small>
              )}
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => {
                    toWithdraw();
                  }}
                  id="transactionAcceptBtn"
                  className="my-3 py-2 w-50 border-0"
                  style={{ backgroundColor: "black", borderRadius: "3px" }}
                >
                  <h6 className="text-white">{t("confirm")}</h6>
                </button>
              </div>
            </div>
          </div>
        )}

        {isLogout && (
          <div id="logoutModal" className="transaction-modal d-flex">
            <div className="transaction-modal-content   px-3">
              <h5 style={{ fontWeight: "bold" }} className=" text-center">
                {t("tips")}
              </h5>
              <h5
                className="text-center"
                style={{
                  color: "rgb(120, 115, 115)",
                  fontWeight: "600",
                  fontSize: "0.9rem",
                }}
              >
                {t("confirmToLogout")}
              </h5>

              <div className="d-flex justify-content-center">
                <div className=" d-flex col-md-10 col-12">
                  <button
                    id="logoutCloseBtn"
                    onClick={() => {
                      setIsLogout(false);
                    }}
                    className="my-3 col-6 py-2 w-50 border-0"
                    style={{
                      backgroundColor: "rgb(180, 176, 176)",
                      borderTopLeftRadius: "15px",
                      borderBottomLeftRadius: "15px",
                    }}
                  >
                    <h6 className="text-black">{t("cancel")}</h6>
                  </button>
                  <button
                    id="logoutConfirm"
                    className="my-3 col-6 py-2 w-50 border-0"
                    onClick={() => {
                      confirmLogout();
                    }}
                    style={{
                      backgroundColor: "black",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                  >
                    <h6 className="text-white">{t("confirm")}</h6>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          id="fakeLogout"
          method="POST"
          type="button"
          onClick={logoutClick}
          class=" w-100 mb-2 py-2 border-0 text-center text-white"
          style={{ backgroundColor: "black", borderRadius: "5px" }}
        >
          <h5 style={{ fontSize: ".9rem" }} class=" mt-1">
            {t("logout")}
          </h5>
          <button type="submit" hidden id="realLogout">
            {t("logout")}
          </button>
        </div>

        {/* {{-- Logout Section --}} */}
        <div>
          <div className="w-100 mt-1 d-flex justify-content-center">
            <small className="copyright">{t("allRightsReserved")}</small>
          </div>
        </div>
        {errorTransactionPassword && (
          <div id="incorrectTransactionPassword" class="notification d-flex ">
            {t("incorrectTransactionPassword")}
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsPage;
