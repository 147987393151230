import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { removeId, removeName, removeToken } from "./utils/localStorage";
import { CiSun } from "react-icons/ci";
import { FaSun } from "react-icons/fa";
import { BsSun } from "react-icons/bs";
import { FaMoon } from "react-icons/fa";
import { useEffect, useState } from "react";
import "./index.css";

export const NavBar = ({ setIsNight, isNight }) => {
  const [location, setLocation] = useState(window.location.href);
  const [isHome, setIsHome] = useState(false);
  const [isRecords, setIsRecords] = useState(false);
  const [isMessages, setIsMessages] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [domain, setDomain] = useState("https://amzscoutmembers.com/");
  useEffect(() => {
    switch (location) {
      case domain:
        setIsHome(true);
        break;
      case `${domain}records`:
        setIsRecords(true);
        break;
      case `${domain}messages`:
        setIsMessages(true);
        break;
      case `${domain}starting`:
        setIsStarting(true);
        break;

      default:
        setIsSettings(true);
        break;
    }
  }, []);
  const cancelNavStyle = () => {
    setIsHome(false);
    setIsSettings(false);
    setIsMessages(false);
    setIsStarting(false);
    setIsRecords(false);
  };
  const navigate = useNavigate();

  return (
    <div className="mainBody  " style={{ backgroundColor: "#fafafa" }}>
      <div className=" mainContainer" style={{ backgroundColor: "#fafafa" }}>
        <div
          className=" mb-5 pb-md-5 pb-4 content-wrapper overflow-hidden   "
          style={{ backgroundColor: "#fafafa" }}
        >
          <Outlet />
        </div>

        <br />

        <div className=" w-100 position-relative  ">
          <div className="mt-5   py-2 footer  position-fixed">
            <div className="">
              <div
                onClick={() => {
                  cancelNavStyle();
                  setIsHome(true);
                  navigate("/");
                }}
                className=" d-flex justify-content-center"
              >
                {isHome ? (
                  <img
                    id="home"
                    src={`${process.env.PUBLIC_URL}/images/Change them into Red/homeActive.png`}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                    alt=""
                  />
                ) : (
                  <img
                    id="home"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/closedHome.png`}
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                    alt=""
                  />
                )}
              </div>
              <div
                style={{ fontSize: "0.8rem" }}
                className=" text-center"
                id="homeText"
              >
                Home
              </div>
            </div>
            <div className=" ">
              <div
                onClick={() => {
                  cancelNavStyle();
                  setIsRecords(true);
                  navigate("/records");
                }}
                className=" d-flex justify-content-center"
              >
                {isRecords ? (
                  <img
                    id="records"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/openRecords.png`}
                    // src="{{asset('icons/No need to edit/closedRecords.png')}}"
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                    alt=""
                  />
                ) : (
                  <img
                    id="records"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/closedRecords.png`}
                    // src="{{asset('icons/No need to edit/closedRecords.png')}}"
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                    alt=""
                  />
                )}
              </div>
              <div
                style={{ fontSize: ".8rem" }}
                className=" text-center "
                id="recordsText"
              >
                Records
              </div>
            </div>
            <div className=" ">
              <div
                onClick={() => {
                  cancelNavStyle();
                  setIsStarting(true);
                  navigate("/starting");
                }}
                className=" d-flex justify-content-center position-relative "
              >
                {isStarting ? (
                  <img
                    id="order"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/mainOrder.png`}
                    style={{ cursor: "pointer" }}
                    className=" position-absolute customBtn"
                    alt=""
                  />
                ) : (
                  <img
                    id="order"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/mainOrderClosed.png`}
                    style={{ cursor: "pointer" }}
                    className=" position-absolute customBtn"
                    alt=""
                  />
                )}
                <div
                  className=" position-absolute "
                  style={{ top: "10px", color: "white" }}
                  id="orderText"
                >
                  Order
                </div>
              </div>
            </div>
            <div className=" ">
              <div
                onClick={() => {
                  cancelNavStyle();
                  setIsMessages(true);
                  navigate("/messages");
                }}
                className=" d-flex justify-content-center"
              >
                {isMessages ? (
                  <img
                    id="messages"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/openedMessages.png`}
                    style={{ cursor: "pointer", width: "30px", height: "30px" }}
                    alt=""
                  />
                ) : (
                  <img
                    id="messages"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/closedMessages.png`}
                    style={{ cursor: "pointer", width: "30px", height: "30px" }}
                    alt=""
                  />
                )}
              </div>
              <div
                style={{ fontSize: ".8rem" }}
                className=" text-center"
                id="messagesText"
              >
                Messages
              </div>
            </div>
            <div className=" ">
              <div
                onClick={() => {
                  cancelNavStyle();
                  setIsSettings(true);
                  navigate("/settings");
                }}
                className=" d-flex justify-content-center"
              >
                {isSettings ? (
                  <img
                    id="settings"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/openedSettings.png`}
                    // src="{{asset('icons/No need to edit/closedSettings.png')}}"
                    style={{ cursor: "pointer", width: "30px", height: "30px" }}
                    alt=""
                  />
                ) : (
                  <img
                    id="settings"
                    src={`${process.env.PUBLIC_URL}/images/No need to edit/closedSettings.png`}
                    // src="{{asset('icons/No need to edit/closedSettings.png')}}"
                    style={{ cursor: "pointer", width: "30px", height: "30px" }}
                    alt=""
                  />
                )}
              </div>
              <div
                style={{ fontSize: ".8rem" }}
                className=" text-center"
                id="settingsText"
              >
                Settings
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
